<template>
    <div class="">
        <div class="card">
            <div class="card-body text-center">
                <h4>{{errorCounter}}</h4>
                <h6 v-if="errorCounter == 1" >Day Since Last Incident</h6>
                <h6 v-else >Days Since Last Incident</h6>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";


export default {
    props: {
        user: {
            type: Object,
            required: false
        }
    },
    components: {
        // incidents,

    },
    data: () => {
        return {
            text: '',
            reportData: [],
            errorCounter: 0,
            counter:{counter:0},
        };
    },
    created() {
        this.getIncident();
    },
    computed: {
        getIncidents() {
            let data = this.errorCounter;
            return data;
        },
    },
    methods:{
       getIncident() {
            var request = {'apikey': this.$root.apikey};
            axios.get(`${this.$root.serverUrl}/admin/reports/incidentsCount`,{params:request}).then((resp) => {
                if(resp.data.error){
                  console.log('Error coming from incident counter')
                }else{
                    this.errorCounter = resp.data.data[0].counter
                    this.$forceUpdate();
                }
            }).catch(() => {
                console.log('page refreshed')
            })
        },
    }

};
</script>

<style>
</style>
