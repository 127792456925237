<template>
    <div class="control-section">
        <ejs-chart style='display:block' ref="columnInstance" align='center' :theme='theme' id='chartcontainer' :title='title' :primaryXAxis='primaryXAxis'  :primaryYAxis='primaryYAxis'
                   :chartArea='chartArea' :width='width' :tooltip='tooltip' :legendSettings='legend' :axisLabelRender='OnChartAxisLabelRender'  :palettes='palettes'>
            <e-series-collection>
                <e-series :dataSource='seriesData' :type='type' stackingGroup = 'crawler' xName='Time' yName='Shown' name='Shown' width=2 columnSpacing = '0.0' columnWidth=0.9 :border='border' :marker='marker' > </e-series>
                <e-series :dataSource='seriesData' :type='type' stackingGroup = 'crawler' xName='Time' yName='Capped' name='Capped' width=2 columnSpacing = '0.0' columnWidth=0.9 :border='border' :marker='marker' > </e-series>
                <e-series :dataSource='seriesData' :type='type2' xName='Time' yName='Total' name='Total' width=2 columnWidth=0.9 columnSpacing = '0.0' :border='border' :marker='marker2' ></e-series>

            </e-series-collection>
        </ejs-chart>
    </div>

</template>
<style scoped>
.chart-content {
    height: 100%;
    width: 100%;
}
#container1, #container2, #container3 {
    height: inherit !important;
    width: inherit !important;
}
#chartcontainer {
    height: 550px;
    width: 100%;
}

@media screen and (max-width: 1024px) {
    #chartcontainer {
        height: 950px; /* Adjust the height for smaller screens */
    }
}
</style>
<script>
import Vue from "vue";
Vue.config.silent = true;
import { Browser } from '@syncfusion/ej2-base';
import { ChartPlugin, StackingColumnSeries, Legend, BarSeries, Category, Tooltip, Highlight,MultiLevelLabel, ColumnSeries, DataLabel,StackingBarSeries,} from "@syncfusion/ej2-vue-charts";
import { createSpinner, showSpinner, hideSpinner } from '@syncfusion/ej2-vue-popups';
Vue.use(ChartPlugin);

let selectedTheme = location.hash.split("/")[1];
selectedTheme = selectedTheme ? selectedTheme : "Material";
let theme = (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)).replace(/-dark/i, "Dark").replace(/contrast/i, 'Contrast');

export default Vue.extend({
    props:['shownRate','isLoading'],
    data: function() {
        return {
            xhrInterval: null,
            chartHeight: '100%', // Set the initial chart height
            chartWidth: '100%',
            theme: theme,
            seriesData: [],
            palettes: ["#22f114", "#e41c1c", "#007bff", "#C4C24A"],
            //Initializing Primary X Axis
            primaryXAxis: {
                valueType: 'Category',
                labelIntersectAction: 'Rotate45',
                majorGridLines: { width: 0 },
                minorGridLines: { width: 0 },
                majorTickLines: { width: 0 },
                minorTickLines: { width: 0 },
            },
            marker: {
                dataLabel: {
                    visible: true,
                    name: 'DataLabelMappingName',
                    position: Browser.isDevice ? 'Middle' : 'Middle',
                    font: { fontWeight: '600', color: Browser.isDevice ? '#404041' : '#0c0c0c' }
                }
            },
            marker2: {
                dataLabel: {
                    visible: true,
                    name: 'DataLabel',
                    position: Browser.isDevice ? 'Top' : 'Top',
                    font: { fontWeight: '600', color: Browser.isDevice ? '#404041' : '#0c0c0c' }
                }
            },
            //Initializing Primary Y Axis
            primaryYAxis:
                {
                    rangePadding: 'None',
                    interval: 2000,
                    title: 'Number Of Clicks Per Hour',
                    lineStyle: { width: 0 },
                    majorTickLines: { width: 0 },
                    majorGridLines: { width: 1 },
                    minorTickLines: { width: 0 },
                },
            chartArea: {
                border: {
                    width: 0
                }
            },
            tooltip: {
                enable: true,
                format: '${point.x} : <b >${point.y} (${point.percentage}%)</b>'
            },
            border: {color: '#ffffff', width:1},
            legend: {enableHighlight : true, },
            width : Browser.isDevice ? '100%' : '100%',
            type: Browser.isDevice ? 'StackingBar' : 'StackingColumn',
            type2: Browser.isDevice ? 'Bar' : 'Column',
            title: "Adretreaver Clicks Distribution"
        };
    },
    mounted() {
        this.$refs.columnInstance.height = "100%";
        this.$refs.columnInstance.width = "100%";
        //createSpinner() method is used to create spinner
        createSpinner({
            // Specify the target for the spinner to show
            target: document.getElementById('chartcontainer')
        });


     showSpinner(document.getElementById('chartcontainer'));
        setInterval(() => {
            // hideSpinner() method used hide spinner
            hideSpinner(document.getElementById('chartcontainer'));
        }, 100000);
    },
    created:function(){
       this.assignData()
        this.xhrInterval = setInterval(this.assignData, 5000);
    },
    provide: {
        chart: [StackingColumnSeries, Category, Tooltip, Legend, Highlight,MultiLevelLabel,ColumnSeries, DataLabel,StackingBarSeries,BarSeries]
    },
    beforeDestroy() {
        clearInterval(this.xhrInterval)
        this.xhrInterval = null;
    },
    methods: {
        OnChartAxisLabelRender : function(args){
            if(args.value > 99 || args.value < -999999) {
                args.text = args.text.replace("0000000","0M").replace("000000", "M");
            }
        },
        assignData(){

            if(this.isLoading == true)  showSpinner(document.getElementById('chartcontainer'));
            const seriesData = [];
            for (let i = 0; i < this.shownRate.labels.length; i++) {
                const Time = this.shownRate.labels[i];
                const itemData = { Time };

                for (const item of this.shownRate.values) {
                    const itemName = item.name;
                    const itemValue = item.values[i];
                    itemData[itemName] = itemValue;
                }

                seriesData.push(itemData);
            }

            this.seriesData = seriesData
            if(this.isLoading == false)  hideSpinner(document.getElementById('chartcontainer'));
        }
    },

});
</script>
