<template>
    <div>
        <ejs-chart id="currentDay"
                   :palettes='palettes'
                   :primaryXAxis="primaryXAxis"
                   :primaryYAxis="primaryYAxis"
                   :tooltip="tooltip"
                   :chartArea="chartArea">
            <e-series-collection>
                <e-series :dataSource="getToday" type="Line" xName="hour" :yName="filter.view" name="Selected Day" width="2" dashArray="5,1" :marker="marker" ></e-series>
                <e-series :dataSource="getPrevious" type="Line" xName="hour" :yName="filter.view" name="Past Day" width="2" dashArray="5,1" :marker="marker" ></e-series>
            </e-series-collection>
        </ejs-chart>
    </div>
</template>

<script>
import Vue from "vue";
import { ChartPlugin, LineSeries, Legend, Tooltip, Category } from "@syncfusion/ej2-vue-charts";

Vue.use(ChartPlugin);

export default {
    name: "currentDay.vue",
    props:['filter'],
    data() {
        return {
            palettes: ["#008000", "#800080"],
            primaryXAxis: {
                title: "Time (24 Hours)",
                majorGridLines: {width: 0},
                minorGridLines: {width: 0},
                majorTickLines: {width: 0},
                minorTickLines: {width: 0},
                interval: 1,
                lineStyle: {width: 0},
                valueType: "Category",
                labelFormat: "%{value}"

            },
            primaryYAxis: {
                title: "Shown Rate",
                lineStyle: {width: 0},
                minimum: 0,
                majorTickLines: {width: 0},
                majorGridLines: {width: 1},
                minorGridLines: {width: 1},
                minorTickLines: {width: 0},
            },
            chartArea: {
                border: {
                    width: 0
                }
            },
            marker: {
                visible: true
            },
            tooltip: {
                enable: true
            }
        };
    },
    provide: {
        chart: [LineSeries, Legend, Tooltip, Category]
    },
    computed: {
        getData() {
            var data = [];
            data = this.$store.state ? this.$store.state.seriesDataDay : data;
            console.log("getting data")
            return data;
        },
        getToday() {
            var data = [];
            data = this.getData.today ? this.getData.today : data;
            return data;
        },
        getPrevious() {
            var data = [];
            data = this.getData.previous ? this.getData.previous : data;
            return data;
        },
    }
}
</script>

<style scoped>

</style>
