<template>
<div class="card mb-5">
    <ejs-grid ref="grid" :dataSource="getData" :allowPaging="true" :toolbar='toolbar' :toolbarClick='toolbarClick'  :allowFiltering="true" :allowExcelExport="true" :allowPdfExport="true" :allowSorting="true" :pageSettings="pageSettings">
        <e-columns>
            <e-column field="client" headerText="Client" text-Align="Left"></e-column>
            <e-column field="domain" headerText="Domain" text-Align="Left" width="130"></e-column>
            <e-column field="impressions" headerText="Impressions" format="N" text-Align="Left" ></e-column>
            <e-column field="bounce_count" headerText="Bounced Count" format="N" text-Align="Left"></e-column>
            <e-column field="bounce_time" headerText="Time of Bounce" text-Align="Left"></e-column>
        </e-columns>
    </ejs-grid>
</div>
</template>

<script>
import Vue from "vue";
import VueChartsCSS from "vue.charts.css";
import { GridPlugin, Page, PdfExport, ExcelExport, Sort, Filter, Group,Toolbar} from '@syncfusion/ej2-vue-grids';
import {ClickEventArgs} from "@syncfusion/ej2-vue-navigations";

Vue.use(GridPlugin);
Vue.use(VueChartsCSS);

export default {
    name: "topBounced",
    props:['getDataset'],
    data: function () {
        return {
            toolbar: ['ExcelExport', 'PdfExport'],
            pageSettings: { pageSize: 50, pageSizes: true },
        }
    },
    computed: {
        getData() {
            let data = [];
            data = this.$store.state ? this.$store.state.bouncedWebsites : data;
            return data;
        },
    },
    provide: {
        grid: [Page, Sort, Filter,Group,Toolbar,PdfExport,ExcelExport]
    },
    methods: {
        toolbarClick: function(args = ClickEventArgs) {

            switch (args.item.text) {
                /* tslint:disable */
                case 'Excel Export':
                    (this.$refs.grid).excelExport(this.getExcelExportProperties());
                    break;
                /* tslint:enable */
                case 'PDF Export':
                    (this.$refs.grid).pdfExport(this.getPdfExportProperties());
                    break;
            }
        },
        getExcelExportProperties: function() {
            return {
                fileName: "topWebsiteBounced_export.xlsx"
            };
        },
        getPdfExportProperties: function() {
            return {
                fileName: "topWebsiteBounced.pdf"
            };
        },
    },
}
</script>

<style scoped>

</style>
