<template>
        <ejs-chart id="dataUsage"
            :primaryXAxis='primaryXAxis' 
            :primaryYAxis="primaryYAxis" 
            :palettes="palettes"
            :tooltip="tooltip"
            :legendSettings="legendSettings"
            >
<!--            <e-series-collection>-->
<!--                <e-series :dataSource='getData' type='StackingBar' xName='time' yName='luminate' name='Luminate' :marker="marker"> </e-series>-->
<!--                <e-series :dataSource='getData' type='StackingBar' xName='time' yName='brightdata' name='Brightdata' :marker="marker" > </e-series>-->
<!--                <e-series :dataSource='getData' type='StackingBar' xName='time' yName='oxylabs' name='Oxylabs' :marker="marker" > </e-series>-->
<!--            </e-series-collection>-->
            <e-series-collection>
                <e-series
                    v-for="(dataset, index) in datasets"
                    :key="index"
                    :dataSource="getData"
                    type="StackingBar"
                    :xName="'time'"
                    :yName="dataset.yName"
                    :name="dataset.name"
                    :marker="marker"
                ></e-series>
            </e-series-collection>
        </ejs-chart>
</template>

<script>
import Vue from "vue";
import { ChartPlugin, StackingBarSeries, Category, Legend, Tooltip, DataLabel } from "@syncfusion/ej2-vue-charts";
Vue.prototype.$eventHub = new Vue();
import { createSpinner, showSpinner, hideSpinner } from '@syncfusion/ej2-vue-popups';
Vue.use(ChartPlugin);

export default {
    name: "data-usage",
    data() {
        return {
            datasets: [
                { yName: '', name: '' },

                // Add more datasets dynamically as needed
            ],
            palettes: ["#304460", "#B84C7A", "#BCA7D2", "#C7D9F4", "#4C808A"],
            primaryXAxis: {
                valueType: 'Category',
                title: 'Time (24hrs)',
                edgeLabelPlacement: 'Shift',
                interval: 1,
            },
            primaryYAxis: {
                title: 'GB/Provider',
                labelFormat: '{value} GB',labelStyle: {
                color: 'transparent'
            }
                // edgeLabelPlacement: 'Shift',
            },
            marker: {
                dataLabel: { visible: true, 
                    position: 'Middle',
                        font: {
                            fontWeight: '600', color: '#ffffff'
                        } },
            },
            animation: { enable: true },
            legendSettings: {
                visible: true,
                position:'Auto' 
            },
            tooltip: { 
                enable: false,
            }, 
        };
    },
    created() {
        // Listen for the global event
        // this.$bus.$on('removeSpinner', this.removeSpinner);
        this.$eventHub.$on('removeSpinnerUsage', () => {

            this.removeSpinner()

        });
    },
    beforeDestroy() {
        // Remove the event listener to avoid memory leaks
        this.$eventHub.$off('removeSpinnerUsage');
    },
    mounted() {

        //createSpinner() method is used to create spinner
        createSpinner({
            // Specify the target for the spinner to show
            target: document.getElementById('dataUsage')
        });


        showSpinner(document.getElementById('dataUsage'));
        setInterval(() => {
            // hideSpinner() method used hide spinner
            hideSpinner(document.getElementById('dataUsage'));
        }, 100000);
    },
    // async created() {
    //     let data = [];
    //     data = this.$store.state ? this.$store.state.dataUsageData : data;
    //     console.log('this is data Data',data)
    //     this.datasets = await this.formatData(data)
    // },
    computed: {

      getData() {
          let data = [];
          data = this.$store.state ? this.$store.state.dataUsageData : data;
          data = data.map(item => {
              const lowercasedItem = {};

              // Iterate through the keys of each object and convert them to lowercase
              Object.keys(item).forEach(key => {
                  lowercasedItem[key.toLowerCase()] = item[key];
              });

              return lowercasedItem;
          });
          // data = data.forEach((op) => op.provider =op.provider.toLowerCase() )
          this.formatData(data)
          // console.log('formatData',data)
          return data;
      },

    },
    methods:{
        removeSpinner(){
            hideSpinner(document.getElementById('dataUsage'));
        },
       async formatData(data){
            // Find the provider array with the highest number of elements
            let maxProviderArray = null;
            let maxProviderArrayLength = -1;

            try{
                data.forEach(obj => {
                    const currentProviderArray = obj.provider || [];
                    const currentProviderArrayLength = currentProviderArray.length;

                    if (currentProviderArrayLength > maxProviderArrayLength) {
                        maxProviderArray = currentProviderArray;
                        maxProviderArrayLength = currentProviderArrayLength;
                    }
                });


                // Filter out empty providers
                const nonEmptyProviders = maxProviderArray.filter(provider => provider.trim() !== null);

                // Create the datasets array dynamically based on the non-empty providers
                const datasets = nonEmptyProviders.map(provider => ({
                    yName: provider.toLowerCase(),  // Assuming provider names are lowercase
                    name: provider.charAt(0).toUpperCase() + provider.slice(1),  // Capitalize the first letter
                }));
                // Output the result
                this.datasets = datasets;
            }catch (e){
             console.log('')
            }
        }

    },
    provide: {
        chart: [StackingBarSeries, Legend, Category, Tooltip, DataLabel]
    }
}
</script>

<style scoped>
    #dataUsage {
        height: 1057px;
    }

</style>
