<template>
    <ejs-chart id="dataUsageDay"
                :palettes='palettes'
                :primaryXAxis="primaryXAxis"
                :primaryYAxis="primaryYAxis"
                :tooltip="tooltip"
                :legendSettings="legendSettings">
<!--        <e-series-collection>-->
<!--            <e-series :dataSource="getData" type="StackingLine" width="2" xName="day" yName='luminate' name='Luminate' dashArray="5,1" :marker="marker"></e-series>-->
<!--            <e-series :dataSource="getData" type="StackingLine" width="2" xName="day" yName='brightdata' name='Brightdata' dashArray="5,1" :marker="marker"></e-series>-->
<!--            <e-series :dataSource="getData" type="StackingLine" width="2" xName="day" yName='oxylabs' name='Oxylabs' dashArray="5,1" :marker="marker"></e-series>-->
<!--        </e-series-collection>-->
        <e-series-collection>
            <e-series
                v-for="(dataset, index) in datasets"
                :key="index"
                :dataSource="getData"
                width="2"
                type="StackingLine"
                xName="day"
                :yName="dataset.yName"
                :name="dataset.name"
                dashArray="5,1"
                :marker="marker"
            ></e-series>
        </e-series-collection>
    </ejs-chart>
</template>

<script>
import Vue from 'vue'
import { createSpinner, showSpinner, hideSpinner } from '@syncfusion/ej2-vue-popups';
import { ChartPlugin, Category, Legend, StackingLineSeries, Tooltip } from "@syncfusion/ej2-vue-charts";
Vue.use(ChartPlugin)

export default {
    name: "data-usage-day",

    data() {
        return {
            datasets: [
                { yName: '', name: '' },

                // Add more datasets dynamically as needed
            ],
            palettes: ["#304460", "#B84C7A", "#BCA7D2", "#C7D9F4", "#4C009A"],
            primaryXAxis: {
                title: "Days",
                majorGridLines: {width: 1},
                minorGridLines: {width: 1},
                majorTickLines: {width: 1},
                minorTickLines: {width: 1},
                interval: 1,
                valueType: "Category",
            },
            primaryYAxis: {
                majorTickLines: {width: 1},
                majorGridLines: {width: 1},
                minorGridLines: {width: 1},
                minorTickLines: {width: 1},
                minimum: 0,
                title: 'GB/Provider',
            },
            legendSettings: {
                visible: true,
            },
            tooltip: { enable: true },
            marker: { visible: true },
        }
    },
    created() {
        // Listen for the global event
        // this.$bus.$on('removeSpinner', this.removeSpinner);
        this.$eventHub.$on('removeSpinner', () => {

            this.removeSpinner()

        });
    },
    beforeDestroy() {
        // Remove the event listener to avoid memory leaks
        this.$eventHub.$off('removeSpinner');
    },
    mounted() {

        //createSpinner() method is used to create spinner
        createSpinner({
            // Specify the target for the spinner to show
            target: document.getElementById('dataUsageDay')
        });


        showSpinner(document.getElementById('dataUsageDay'));
        setInterval(() => {
            // hideSpinner() method used hide spinner
            hideSpinner(document.getElementById('dataUsageDay'));
        }, 100000);
    },
    provide: {
        chart: [StackingLineSeries, Legend, Tooltip, Category]
    },
    computed: {
        getData() {
            showSpinner(document.getElementById('dataUsageDay'));
            return this.handleData()
        },
    },
    methods:{
        removeSpinner(){

            this.$forceUpdate();
            this.handleData()
            hideSpinner(document.getElementById('dataUsageDay'));
        },
        handleData(){
            let data = [];
            data = this.$store.state ? this.$store.state.dayDataUsageData : data;

            data = data.map(item => {
                const lowercasedItem = {};

                // Iterate through the keys of each object and convert them to lowercase
                Object.keys(item).forEach(key => {
                    lowercasedItem[key.toLowerCase()] = item[key];
                });

                return lowercasedItem;
            });

            // data = data.forEach((op) => op.provider =op.provider.toLowerCase() )
            this.formatData(data)
            return data;
        },
        async formatData(data){


            // Find the provider array with the highest number of elements
            let maxProviderArray = null;
            let maxProviderArrayLength = -1;
            try{
                data.forEach(obj => {
                    const currentProviderArray = obj.provider || [];
                    const currentProviderArrayLength = currentProviderArray.length;

                    if (currentProviderArrayLength > maxProviderArrayLength) {
                        maxProviderArray = currentProviderArray;
                        maxProviderArrayLength = currentProviderArrayLength;
                    }
                });

                // Filter out empty providers
                const nonEmptyProviders = (maxProviderArray || []).filter(provider => provider !== null);

                // Create the datasets array dynamically based on the non-empty providers
                const datasets = nonEmptyProviders.map(provider => ({
                    yName: provider.toLowerCase(),  // Assuming provider names are lowercase
                    name: provider.charAt(0).toUpperCase() + provider.slice(1),  // Capitalize the first letter
                }));
                this.datasets = datasets;
            }catch (e){
                console.log(e)
            }

        }

    },
}
</script>

<style scoped>

</style>
