<template>
    <ejs-chart id="perImpression"
               :palettes='palettes'
               :primaryXAxis="primaryXAxis"
               :primaryYAxis="primaryYAxis"
               :tooltip="tooltip"
               :legendSettings="legendSettings">
        <e-series-collection>
            <e-series :dataSource="getData" type="StackingLine" width="2" xName="time" yName='luminate' name='Luminate' dashArray="5,1" :marker="marker"></e-series>
            <e-series :dataSource="getData" type="StackingLine" width="2" xName="time" yName='brightdata' name='Brightdata' dashArray="5,1" :marker="marker"></e-series>
            <e-series :dataSource="getData" type="StackingLine" width="2" xName="time" yName='oxylabs' name='Oxylabs' dashArray="5,1" :marker="marker"></e-series>
        </e-series-collection>
        <e-series-collection>
            <e-series
                v-for="(dataset, index) in datasets"
                :key="index"
                :dataSource="getData"
                dashArray="5,1"
                type="StackingLine"
                :xName="'time'"
                :yName="dataset.yName"
                :name="dataset.name"
                :marker="marker"
            ></e-series>
        </e-series-collection>
    </ejs-chart>
</template>

<script>
import Vue from 'vue'
Vue.prototype.$eventHub = new Vue();
import { createSpinner, showSpinner, hideSpinner } from '@syncfusion/ej2-vue-popups';
import { ChartPlugin, Category, Legend, StackingLineSeries, Tooltip } from "@syncfusion/ej2-vue-charts";
Vue.use(ChartPlugin)

export default {
    name: "per-impression",

    data() {
        return {
            datasets: [
                { yName: '', name: '' },

                // Add more datasets dynamically as needed
            ],
            palettes: ["#304460", "#B84C7A", "#BCA7D2", "#C7D9F4", "#4C808A"],
            primaryXAxis: {
                title: "Time (24 Hours)",
                majorGridLines: {width: 1},
                minorGridLines: {width: 1},
                majorTickLines: {width: 1},
                minorTickLines: {width: 1},
                interval: 1,
                valueType: "Category",
            },
            primaryYAxis: {
                majorTickLines: {width: 1},
                majorGridLines: {width: 1},
                minorGridLines: {width: 1},
                minorTickLines: {width: 1},
                title: 'GB/Impression',
            },
            legendSettings: {
                visible: true,
            },
            tooltip: { enable: true },
            marker: { visible: true },
        }
    },
    created() {
        // Listen for the global event
        // this.$bus.$on('removeSpinner', this.removeSpinner);
        this.$eventHub.$on('removeSpinnerImpression', () =>this.removeSpinner());
        this.$eventHub.$on('showSpinnerImpression', () => this.showSpinner());
    },
    beforeDestroy() {
        // Remove the event listener to avoid memory leaks
        this.$eventHub.$off('removeSpinnerImpression');
        this.$eventHub.$off('showSpinnerImpression');
    },
    mounted() {

        //createSpinner() method is used to create spinner
        createSpinner({
            // Specify the target for the spinner to show
            target: document.getElementById('perImpression')
        });


        showSpinner(document.getElementById('perImpression'));
        setInterval(() => {
            // hideSpinner() method used hide spinner
            hideSpinner(document.getElementById('perImpression'));
        }, 100000);
    },
    provide: {
        chart: [StackingLineSeries, Legend, Tooltip, Category]
    },
    computed: {
        getData() {
            // showSpinner(document.getElementById('perImpression'));
            let data = [];
            data = this.$store.state ? this.$store.state.impressionData : data;
            data = data.map(item => {
                const lowercasedItem = {};

                // Iterate through the keys of each object and convert them to lowercase
                Object.keys(item).forEach(key => {
                    lowercasedItem[key.toLowerCase()] = item[key];
                });

                return lowercasedItem;
            });
            // data = data.forEach((op) => op.provider =op.provider.toLowerCase() )
            this.formatData(data)
            // console.log('formatData',data)
            return data;
        },
    },
    methods:{
        removeSpinner(){
            hideSpinner(document.getElementById('perImpression'));
        },
        showSpinner(){
            showSpinner(document.getElementById('perImpression'));
        },
        async formatData(data){
            // Find the provider array with the highest number of elements
            let maxProviderArray = null;
            let maxProviderArrayLength = -1;

            data.forEach(obj => {
                const currentProviderArray = obj.provider || [];
                const currentProviderArrayLength = currentProviderArray.length;

                if (currentProviderArrayLength > maxProviderArrayLength) {
                    maxProviderArray = currentProviderArray;
                    maxProviderArrayLength = currentProviderArrayLength;
                }
            });

            // Filter out empty providers
            const nonEmptyProviders = (maxProviderArray || []).filter(provider => provider.trim() !== null);

            // Create the datasets array dynamically based on the non-empty providers
            const datasets = nonEmptyProviders.map(provider => ({
                yName: provider.toLowerCase(),  // Assuming provider names are lowercase
                name: provider.charAt(0).toUpperCase() + provider.slice(1),  // Capitalize the first letter
            }));

            // Output the result
            this.datasets = datasets;
        }

    },
}
</script>

<style scoped>

</style>
