<template>
    <div>
        <div class="row">
            <div class="col-lg-10">
                <h4 class="border-bottom pb-2 mb-3"><i class="fa fa-dashboard"></i> Dashboard / <span class="text-muted">System overview and management</span></h4>

            </div>
            <div class="col-lg-2 mb-2">
                <select name="" id="" v-model="dashboard" class="btn btn-info dropdown-toggle">
                    <option value="main">Operations</option>
                    <option value="monitor">ClickMonitoring</option>
                    <option value="proxy">ProxyManagement</option>
                    <option value="inboxEhanced">Inbox Enhanced</option>
                </select>
            </div>
        </div>

        <div class="row">
            <div class="col-12" v-if="dashboard == 'main'" >
                <div class="row">
                    <MainBoard :user="user" class="col-12" />
                </div>
            </div>
            <div class="col-12" v-if="dashboard == 'monitor'" >
                <div class="row">
                    <ClickBoard :user="user" class="col-12" />
                    <!-- <div class="col-12 mb-2">
                        <div class="card">
                            <div class="card-body text-center">
                                <div v-if="!loadActive">
                                    <h4>{{Number(Number(bandwidth.download||0) / 1024/1024/1024/1000).toLocaleString()}} TB</h4>
                                    <h6>Download</h6>
                                </div>
                                <h5 v-if="loadActive"> <img src="/25.gif"/></h5>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 mb-2">
                        <div class="card">
                            <div class="card-body text-center">
                                <div v-if="!loadActive">
                                    <h4>{{Number(Number(bandwidth.upload||0) / 1024/1024/1024/1000).toLocaleString()}} TB</h4>
                                    <h6>Upload</h6>
                                </div>
                                <h4 v-if="loadActive"> <img src="/25.gif"/></h4>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 mb-2">
                        <div class="card">
                            <div class="card-body text-center">
                                <div v-if="!loadActive">
                                    <h4>{{Number(Number(bandwidth.total||0) / 1024/1024/1024/1000).toLocaleString()}} TB</h4>
                                    <h6>Total Usage</h6>
                                </div>
                                <h4 v-if="loadActive"> <img src="/25.gif"/></h4>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 mb-2">
                        <div class="card">
                            <div class="card-body text-center">
                                <div v-if="!loadActive">
                                    <h4>{{Number(bandwidth.requests||0).toLocaleString()}}</h4>
                                    <h6>Requests</h6>
                                </div>
                                <h4 v-if="loadActive"> <img src="/25.gif"/></h4>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>

            <!--<div class="col-12 col-sm-4 mb-3">
                <h6 class="border-bottom pb-2 mb-3">
                    <i class="fa fa-stack-exchange"></i> SI Vendor Summary
                    <button type="button" class="btn btn-primary btn-sm mr-2" @click="loadVendors"><i class="fa fa-refresh"></i> Refresh</button>
                </h6>

                <div v-if="!loadData2">
                    <ul class="shownvendors list-group list-group-flush">
                        <li class="list-group-item d-flex justify-content-between align-items-center" v-for="(data, idx) in vendor.data.rows" v-bind:key="data._id" :class="{active: idx == 0}">
                            <div>{{data.TrafficSource}}</div>
                            <div><span class="badge badge-primary font-weight-bold">#{{data.Total||0}}</span></div>
                            <div><span class="badge badge-primary font-weight-bold">{{data.S||0}}</span></div>
                        </li>
                    </ul>
                </div>
            </div>-->

            <!-- <div class="col-12 col-sm-6 mb-3">
                <h6 class="border-bottom pb-2 mb-3">
                    <i class="fa fa-stack-exchange"></i> Shown Summary
                    <span v-if="!isMobile()">
                        <button type="button" class="btn btn-sm btn-primary mr-2" @click="load"><i class="fa fa-refresh"></i> Refresh</button>
                        <button type="button" @click="hourlyReport" class="btn btn-sm mr-2" style="background-color: #FF8C00">View 3 Hour Report</button>
                        <button type="button" @click="$router.push('/capped-and-shown-report')" class="btn btn-sm" style="background-color: #FF6347">View Report</button>
                    </span>
                </h6>
                <div class="mb-3" v-if="isMobile()">
                    <button type="button" class="btn btn-sm btn-primary mr-2" @click="load"><i class="fa fa-refresh"></i> Refresh</button>
                    <button type="button" @click="hourlyReport" class="btn btn-sm mr-2" style="background-color: #FF8C00">3 Hour Report</button>
                    <button type="button" @click="$router.push('/capped-and-shown-report')" class="btn btn-sm" style="background-color: #FF6347">View Report</button>
                </div>
                <div v-if="!loadData">
                    <ul class="shownsummary list-group list-group-flush">
                        <li class="list-group-item d-flex justify-content-between align-items-center" v-for="(data, idx) in shown.data" v-bind:key="data._id" :class="{active: idx == 0}">
                            <div>Total <span class="badge badge-primary font-weight-bold">{{data.data.summary.Total}}</span></div>
                            <div>Shown <span class="badge badge-primary font-weight-bold">{{data.data.summary.S}}</span></div>
                            <div><span class="font-weight-bold"><button type="button" class="btn btn-primary" @click="load_campData(data.data.rows,data.data.summary)"  data-toggle="modal" data-target="#exampleModal">
                                Details
                            </button></span></div>
                            <div class="time">{{new Date(data.created_ts).toLocaleTimeString()}}</div>
                        </li>
                    </ul>
                </div>
            </div> -->
            <div class="col-12 " v-if="dashboard == 'proxy'" >
                <div class="row">
                    <ProxyBoard :user="user" class="col-12" />
                </div>
            </div>
            <div class="col-12 " v-if="dashboard == 'inboxEhanced'" >
                <div class="row">
                    <inboxEnhanced class="col-12" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import swal from "sweetalert";
    // import filters from '../filters'
// import qso from "@/components/shared/qso";
// import statebar from "@/components/monitoring/statebar";
import MainBoard from "@/components/dashboard/main/Dashboard";
import ClickBoard from "@/components/dashboard/monitoring/Dashboard";
import ProxyBoard from "@/components/dashboard/proxy/Dashboard";
import inboxEnhanced from "@/components/dashboard/inboxEnhanced/main";
// import clicksneeded from "@/components/shared/clicksneeded";
// import proxyband from "@/components/shared/proxyband";

    //import vPagination from 'vue-plain-pagination'
    export default {
        name: 'mainDashboard',
        props:['user'],
        components: {
            MainBoard,
            ClickBoard,
            ProxyBoard,
            inboxEnhanced
            // qso,
            // clicksneeded,
            // statebar,
            // proxyband
        },
        data: function () {
            return {
                dashboard:'main',
                showndata: null,
                shownrate: {labels:[], values:[]},
                showCampaigns: true,
                showECampaignsRep: true,
                showNodes: true,
                //summary: {active:0, total:0, performance:0, hour:0},
                stats:{},
                refreshData:'off',
                stat:{},
                status:{active:'active', pending:'pending',done:'done',proxy:'proxy'},
                campStat:{},
                camp:{},
                shown:{},
                vendor:{},
                campaignsList:[],
                hourlyReportData: [],
                hourlyReportModal: false,
                loadData:false,
                loadData2:false,
                loadActive:false,
                loadProxy:false,
                loadPending:false,
                loadDone:false,
                timer:true,
                max: 102,
                page: 0,
                sort:{
                    name:'',
                    daily:'',
                    max:'',
                    goodHits:'',
                    badHits:'',
                    filterType:''
                },
                bootstrapPaginationClasses: {
                    ul: 'pagination',
                    li: 'page-item',
                    liActive: 'active',
                    liDisable: 'disabled',
                    button: 'page-link'
                },
                customLabels: {
                    first: 'First',
                    prev: 'Previous',
                    next: 'Next',
                    last: 'Last'
                },
                filterType: null,
                searched: false,
                missedTotal:0,
                missedClicks:0,
                ecampaignLoading: false,
                //bandwidth: {total: 0, download: 0, upload: 0, requests: 0},
                eventBandwidth: null,
                eventShown: null,
                eventWorkers: null,
                calculatedShown: {total:0, capped:0, shown: 0, percentage: 0, length:0}
            }
        },
        computed: {
            shownType(){
                let mobile = this.isMobile();
                return mobile? "bar" : "column";
            },
            fields() {
                return [
                    {
                        key: 'TrafficSource',
                        label: 'TrafficSource',
                        sortable: true
                    },{
                        key: 'Total',
                        label: 'Total',
                        sortable: true
                    },{
                        key: 'Matched',
                        label: 'Matched',
                        sortable: true
                    },{
                        key: 'M',
                        label: 'M %',
                        sortable: true
                    },{
                        key: 'Shown',
                        label: 'Shown',
                        sortable: true
                    },{
                        key: 'S',
                        label: 'S %',
                        sortable: true
                    },{
                        key: 'Capped',
                        label: 'Capped',
                        sortable: true
                    },{
                        key: 'C',
                        label: 'C %',
                        sortable: true
                    },{
                        key: 'created_ts',
                        label: 'Date',
                        sortable: true
                    },
                ]
            },
            convertVender() {
                let prev = []
                if( this.vendor != undefined){
                    this.vendor.data.rows.forEach( ( curr ) => {

                        let obj = {
                            C: curr.C,
                            Capped: parseInt( curr.Capped.replace(/,/g, ''), 10 ),
                            M: curr.M,
                            Matched: parseInt( curr.Matched.replace(/,/g, ''), 10 ),
                            S: curr.S,
                            Shown: parseInt( curr.Shown.replace(/,/g, ''), 10 ),
                            Total: parseInt( curr.Total.replace(/,/g, ''), 10 ),
                            TrafficSource: curr.TrafficSource,
                            Created_ts : this.vendor.created_ts

                        }
                        prev.push(obj)
                    })
                }


                return prev
            }
        },
        created: function(){
            // this.sendEmail();
            // this.timezoneCheck();
            // this.loadStats();
            //this.loadCampaignStats();
            // this.load();
            // this.loadVendors();

            //Register bandwidth eventstream
            if(!this.eventBandwidth) {
                this.eventBandwidth = new EventSource(`${this.$root.serverUrl}/static/dashboard/bandwidth`, {withCredentials: false});

                this.eventBandwidth.onerror = (error) => {
                    console.log(`eventBandwidth.error`, error);
                }
                this.eventBandwidth.onopen = (args) => {
                    console.log(`eventBandwidth.open`, args);
                }
                this.eventBandwidth.onmessage = (event) => {
                    //console.log(`eventBandwidth.event`, event);
                    try {
                        const parsedData = JSON.parse(event.data);
                        this.bandwidth = Object.assign({total: 0, download: 0, upload: 0, requests: 0}, parsedData);
                        this.$forceUpdate();
                    } catch (e) {
                        console.log(`eventBandwidth.catch: ${e.message}`);
                    }
                };
            }

            //Register shown eventstream
            // if(!this.eventShown) {
            //     this.eventShown = new EventSource(`${this.$root.serverUrl}/static/dashboard/shown`, {withCredentials: false});

            //     this.eventShown.onerror = (error) => {
            //         console.log(`eventShown.error`, error);
            //     }
            //     this.eventShown.onopen = (args) => {
            //         console.log(`eventShown.open`, args);
            //     }
            //     this.eventShown.onmessage = (event) => {
            //         //console.log(`eventShown.event`, event);
            //         try {
            //             const parsedData = JSON.parse(event.data);

            //             //Process the shown data
            //             if(parsedData && parsedData.length){
            //                 this.shownrate.labels = [];
            //                 this.shownrate.values = [];
            //                 let values = [];
            //                 let capped = [];
            //                 let totals = [];

            //                 for(var h=0;h<24;h++){
            //                     let suffix = (h >= 12)? 'pm' : 'am';
            //                     let hours = (h > 12) ? h - 12 : h;
            //                     hours = (hours == 0) ? 12 : hours;
            //                     this.shownrate.labels[h] = `${hours} ${suffix}`;
            //                     values[h] = 0;
            //                     capped[h] = 0;
            //                     totals[h] = 0;
            //                 }

            //                 this.calculatedShown.capped = 0;
            //                 this.calculatedShown.total = 0;
            //                 this.calculatedShown.shown = 0;
            //                 this.calculatedShown.percentage = 0;
            //                 this.calculatedShown.length = 0;
            //                 for(var r=0;r<parsedData.length;r++) {
            //                     //let suffix = (parsedData[r]._id >= 12) ? 'pm' : 'am';
            //                     let hours = (parsedData[r]._id > 12) ? parsedData[r]._id - 12 : parsedData[r]._id;
            //                     hours = (hours == 0) ? 12 : hours;
            //                     values[parsedData[r]._id] = parsedData[r].shown;
            //                     capped[parsedData[r]._id] = parsedData[r].capped;
            //                     totals[parsedData[r]._id] = parsedData[r].total;
            //                     this.calculatedShown.capped += Number(parsedData[r].capped);
            //                     this.calculatedShown.total += Number(parsedData[r].total);
            //                     this.calculatedShown.shown += Number(parsedData[r].shown);
            //                     this.calculatedShown.percentage += Number(parsedData[r].shownrate);
            //                     this.calculatedShown.length = parsedData.length;
            //                 }

            //                 this.shownrate.values.push({name: "Shown", values: values});
            //                 this.shownrate.values.push({name: "Capped", values: capped});
            //                 this.shownrate.values.push({name: "Total", values: totals});

            //                 this.calculatedShown.percentage = (this.calculatedShown.percentage / this.calculatedShown.length) * 100;
            //             }

            //             this.$forceUpdate();
            //         } catch (e) {
            //             console.log(`eventShown.catch: ${e.message}`);
            //         }
            //     };
            // }

            //Register worker eventstream
            /*if(!this.eventWorkers) {
                this.eventWorkers = new EventSource(`${this.$root.serverUrl}/static/workers/stats`, {withCredentials: false});

                this.eventWorkers.onerror = (error) => {
                    console.log(`eventWorkers.error`, error);
                }
                this.eventWorkers.onopen = (args) => {
                    console.log(`eventWorkers.open`, args);
                }
                this.eventWorkers.onmessage = (event) => {
                    //console.log(`eventWorkers.event`, event);
                    try {
                        const parsedData = JSON.parse(event.data);
                        this.summary = Object.assign({active: 0, total: 0, performance: 0, hour: 0}, parsedData);
                        this.$forceUpdate();
                    } catch (e) {
                        console.log(`eventWorkers.catch: ${e.message}`);
                    }
                };
            }*/
            this.$root.preloader = false;
        },
        beforeDestroy: function(){
            this.timer = false;
            clearTimeout(this.interval);
            if(this.eventBandwidth) {
                this.eventBandwidth.close();
                this.eventBandwidth = null;
            }
            /*if(this.eventWorkers) {
                this.eventWorkers.close();
                this.eventWorkers = null;
            }*/
            if(this.eventShown) {
                this.eventShown.close();
                this.eventShown = null;
            }
        },
        methods:{
            isMobile() {
                if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    return true
                } else {
                    return false
                }
            },
            timezoneCheck() {
                if ( this.user && !this.user.timezone ) {
                    this.$bvToast.toast('Select a Timezone in Profile Settings.', {
                        title: `Warning: No Timezone`,
                        variant: 'warning',
                        solid: true
                    })
                }
            },
            load: function(){
                //Load the campaign
                var page
                if(event && event.type == "click" && event.currentTarget && event.currentTarget.className.indexOf(this.bootstrapPaginationClasses.button) > -1){
                    //Pagination triggered event
                    console.log(`Pagination event: ${this.page}: ${event.currentTarget.className}`)
                    page = (this.page)? ((this.page == 1)? 0 : Number(this.page-1) ) : 0
                } else
                    page = 0;

                var request = {'apikey': this.$root.apikey};
                request.page = page
                request.max = 10;

                this.loadData = true;

                return axios.get(`${this.$root.serverUrl}/admin/shownrate`, {params:request}).then(function(resp){
                    this.shown = (resp.data && resp.data.data)? resp.data : null;
                    this.loadData = false;
                }.bind(this));
            },
            loadVendors: function(){
                //Load the campaign
                var page
                if(event && event.type == "click" && event.currentTarget && event.currentTarget.className.indexOf(this.bootstrapPaginationClasses.button) > -1){
                    //Pagination triggered event
                    console.log(`Pagination event: ${this.page}: ${event.currentTarget.className}`)
                    page = (this.page)? ((this.page == 1)? 0 : Number(this.page-1) ) : 0
                } else
                    page = 0;

                var request = {'apikey': this.$root.apikey};
                request.page = page
                request.max = 5

                this.loadData2 = true;

                return axios.get(`${this.$root.serverUrl}/admin/shownrate/vendor`, {params:request}).then(function(resp){

                    if(resp.data && resp.data.data){

                        this.vendor = resp.data.data[0]
                        this.loadData2 = false;
                    } else{
                        this.vendor = null;
                        this.loadData2 = false;
                    }

                }.bind(this));
            },
            load_campData: function (x,y){
                this.campStat = {};
                this.camp = {};
                this.camp = x;
                this.campStat = y;
            },
            hourlyReport: function(){
                const request = {'apikey': this.$root.apikey};
                request.timestamp = Date.now();
                return axios.get(`${this.$root.serverUrl}/admin/shownrate/hourly-summary`, {params:request}).then(function(resp){
                    if(resp.data && resp.data.length > 0){
                        this.hourlyReportData = [];
                        resp.data[0].Timeframe = "Current Hour";
                        resp.data[1].Timeframe = "Previous Hour";
                        resp.data[2].Timeframe = "Past Hour";
                        this.hourlyReportData.push(resp.data[0]);
                        this.hourlyReportData.push(resp.data[1]);
                        this.hourlyReportData.push(resp.data[2]);
                        this.hourlyReportModal = true;
                    }
                }.bind(this));
            },
            loadStats: function(){
                // this.$root.preloader = true;

                var request = {'apikey': this.$root.apikey}

                //request.page = page
                request.page = this.page;
                request.max = this.max;

                return axios.get(`${this.$root.serverUrl}/admin/stats`, {params:request}).then(function(resp){
                    this.stats = (resp && resp.data)? resp.data.data : {};
                    this.$root.preloader = false;
                }.bind(this)).catch(function (err) {
                    this.$root.preloader = false;
                    swal("Oops!",err.message, "error");
                }.bind(this));
            },
            sendEmail: function(){
                var request = {'apikey': this.$root.apikey}
                return axios.post(`${this.$root.serverUrl}/static//send-email`, {params:request}).then(function(resp){
                    this.campaignsList = (resp && resp.data)? resp.data.data.campaigns.list : {};
                    this.$root.preloader = false;
                }.bind(this)).catch(function (err) {
                    this.$root.preloader = false;
                    swal("Oops!",err.message, "error");
                }.bind(this));

            },
            loadCampaignStats: function(){
                //this.$root.preloader = true;

                var request = {'apikey': this.$root.apikey}

                //request.page = page
                request.page = this.page;
                request.max = this.max;
                request.sortName = this.sort.name;
                request.sortDaily = this.sort.daily;
                request.sortMax = this.sort.max;
                request.sortGoodHits = this.sort.goodHits;
                request.sortBadHits = this.sort.badHits;
                request.filterType = this.sort.filterType;
                this.campaignsList = [];

                return axios.get(`${this.$root.serverUrl}/admin/campaign-stats`, {params:request}).then(function(resp){
                    this.campaignsList = (resp && resp.data)? resp.data.data.campaigns.list : {};
                    this.$root.preloader = false;
                }.bind(this)).catch(function (err) {
                    this.$root.preloader = false;
                    swal("Oops!",err.message, "error");
                }.bind(this));
            },
            moniter: function(){
                this.loadStats().finally(function(){
                    if(this.timer)
                        setTimeout(this.moniter, 15000 );
                }.bind(this));
            },
            setFilter: function(filter){
                if(this.searching) return;
                switch (filter){
                    case 'today': { this.filterType = "Today"; break; }
                    case 'yesterday': { this.filterType = "Yesterday"; break; }
                    case '2daysago': { this.filterType = "2 Days Ago"; break; }
                    default: { this.filterType = "Today"; }
                }
            },
            filterECampaigns: function(){
                if(this.ecampaignLoading) return;
                this.ecampaignLoading = true;
                const request = {
                    'apikey': this.$root.apikey,
                };

                switch (this.filterType){
                    case "Today": { request.date = "today"; break; }
                    case "Yesterday": { request.date = "yesterday"; break; }
                    case "2 Days Ago": { request.date = "2daysago"; break; }
                }
                this.missedTotal = 0;
                this.missedClicks = 0;

                axios.get(`${this.$root.serverUrl}/admin/campaigns/e-campaign-filter`,{params:request}).then(function(resp){
                    //Store the stats
                    this.searched = true;
                    let missing = resp.data.missing;
                    if(missing.length === 0){
                        this.ecampaignLoading = false;
                        return;
                    }
                    this.missedTotal = Number(missing.length).toLocaleString();
                    let total = 0;
                    for(let i in missing){
                        total += missing[i].hits;
                    }
                    this.missedClicks = Number(total).toLocaleString();
                    this.ecampaignLoading = false;
                }.bind(this)).catch(() => {
                    // eslint-disable-next-line no-undef
                    swal("Oops!","An error occurred on the server. Try refreshing the page.","error");
                    this.ecampaignLoading = false;
                });
            }
        },
    }
</script>

<style scoped>
.btn-info {
    color: #fff;
    background-color: #117f90;
    border-color: #17a2b8;
}
    .campaigns {}
    .campaigns .campaign {}
    .campaigns .campaign .name {
        font-size: smaller;
        font-family: "Droid Sans Mono Slashed";
        text-transform: capitalize;
    }
    .campaigns .campaign .hits {}
    .campaigns .campaign .daily-max {}
    #shownrate table.charts-css.column {
        height: 400px;
        max-width: 100%;
        margin: 0 auto;
        --labels-size: 3rem;

        --color-1: rgba(43, 230, 30, 1.0);
        --color-2: rgba(230, 30, 30, 1.0);
        --color-3: rgba(0, 123, 255, 1.0);
    }
    #shownrate table.charts-css.column td {
        border-radius: 8px;
    }
    #shownrate table.charts-css.column td .data {
        font-size: medium;
        font-weight: bold;
        padding: 10px 5px;
        color: #333333;
    }

    #shownrate table.charts-css.bar {
        --color-1: rgba(43, 230, 30, 1.0);
        --color-2: rgba(230, 30, 30, 1.0);
        --color-3: rgba(0, 123, 255, 1.0);
    }
    #shownrate table.charts-css.bar td {
        border-radius: 0px;
    }
    #shownrate table.charts-css.bar td .data {
        font-size: medium;
        font-weight: bold;
        padding: 5px 2px;
        color: #333333;
    }

    .shownsummary.list-group {}
    .shownsummary.list-group .list-group-item {
        margin-bottom: 4px;
    }
    .shownsummary.list-group .list-group-item .badge {
        padding: 0.5em 0.8em;
    }
    .shownsummary.list-group .list-group-item .time {
        font-size: 11px;
    }

    .shownvendors.list-group {}
    .shownvendors.list-group .list-group-item {
        margin-bottom: 4px;
    }
    .shownvendors.list-group .list-group-item .badge {
        padding: 0.5em 0.8em;
    }
    .shownvendors.list-group .list-group-item .time {
        font-size: 11px;
    }
</style>
