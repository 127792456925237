<template>
<div >
    <!--section one Filters-->

    <div class="card">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-2 col-md-3">
                    <div  class="form-group mr-1">
                        <div class="tabs-wrap" style="padding-top: 30px; padding-bottom: 30px">
                            <h6 class="font-weight-bold">Client</h6>
                            <div class="wrap">
                                <select
                                    v-model="filters.client"
                                    class="form-control text-capitalize required"
                                    required
                                >
                                    <option value="" selected>-- Any Client --</option>
                                    <option
                                        v-for="item in dropdownClients"
                                        :key="item.value"
                                        :value="item.value"
                                    >
                                        {{ item.text }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-2">
                    <div class="tabs-wrap" style="padding-top: 30px; padding-bottom: 30px">
                        <h6 class="font-weight-bold">Campaign</h6>
                        <div class="wrap">
                            <select v-model="filters.campaign" class="form-control">
                                <option value="" selected>-- Any Campaign--</option>
                                <option v-for="product in dropdownCampaigns" :key="product.id" :value="product.id">{{ product.name }}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="col-lg-2 col-md-2">
                    <div class="tabs-wrap" style="padding-top: 30px; padding-bottom: 30px">
                        <h6 class="font-weight-bold">Time Period</h6>
                        <div class="wrap">
                            <select v-model="filters.timePeriod" class="form-control">
                                <option value="" selected>-- Select Time Period--</option>
                                <option value="Hourly" >Hourly</option>
                                <option value="Daily" >Daily</option>
                                <option value="Weekly" >Weekly</option>
                                <option value="monthly" >Monthly</option>
                                <option value="quarterly" >Quarterly</option>
                                <option value="Yearly" >Yearly</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-lg-2 col-md-2">
                    <div  class="form-group mr-1">
                        <div class="tabs-wrap" style="padding-top: 30px; padding-bottom: 30px">
                            <h6 class="font-weight-bold"> Start Date</h6>
                            <div class="wrap">
                                <input type="date" v-model="filters.start"  class="form-control date" placeholder="to"/>
<!--                                <ejs-datepicker cssClass="e-outline" id='startPicker' :serverTimezoneOffset="timezone" placeholder="start" v-model="filters.start" ></ejs-datepicker>-->
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-2 col-md-2">
                    <div  class="form-group mr-1">
                        <div class="tabs-wrap" style="padding-top: 30px; padding-bottom: 30px">
                            <h6 class="font-weight-bold">End Date</h6>
                            <div class="wrap">
                                <input type="date" v-model="filters.end"  class="form-control date" placeholder="End"/>
<!--                                <ejs-datepicker cssClass="e-outline" id='endPicker' :serverTimezoneOffset="timezone" placeholder="start" v-model="filters.end" ></ejs-datepicker>-->
                            </div>
                        </div>
                    </div>
                </div>


                    <div class="col-lg-2 col-md-2">
                        <div  class="form-group mr-1">
                        <div class="tabs-wrap  pt-5">
                            <h6 class="font-weight-bold"></h6>
                            <button class="btn btn-primary btn-sm mr-1" data-ripple="true" aria-label="SEARCH progress"  @click="search">Search</button>
                            <button @click="exportToPDF" class="btn btn-success btn-sm pr-3">Export to PDF</button>
                        </div>


                        </div>
                    </div>


            </div>

        </div>
    </div>
    <section ref="contentToCapture" id="content">
<!--section Two Summary-->
    <div class="mt-5 container col-12">
        <h6 class=" font-weight-bolder"> Overview Summary - <span class="text-danger">  * Overview summary is displayed based on the selected Date Range and is irrespective of Time Period *
 </span></h6>
        <div class="card-group">
            <div class="card">
                <div class="card-body">
                    <h6 class="card-title">Total Opened Emails <i class="fa fa-info-circle" title="Total number of opened emails"></i></h6>
                    <h4 class="card-text font-weight-bolder">{{totalNumberOfOpen}}</h4>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h6 class="card-title">Total Forwarded Emails  <i class="fa fa-info-circle" title="Total number of forwarded emails"></i></h6>
                    <h4 class="card-text font-weight-bolder">{{totalNumberOfForward}}</h4>

                </div>
            </div>
            <div class="card">

                <div class="card-body">
                    <h6 class="card-title">Total Deleted Emails  <i class="fa fa-info-circle" title="Total number of deleted emails"></i></h6>
                    <h4 class="card-text font-weight-bolder">{{totalNumberOfDelete}}</h4>
                </div>
            </div>
            <div class="card">

                <div class="card-body">
                    <h6 class="card-title">Total Replied Emails  <i class="fa fa-info-circle" title="Total number of replied emails"></i></h6>
                    <h4 class="card-text font-weight-bolder">{{totalNumberOfReply}}</h4>
                </div>
            </div>
        </div>

        <div class="card-group">
            <div class="card">
                <div class="card-body">
                    <h6 class="card-title">Average Opened Emails  <i class="fa fa-info-circle" title="Average number of opened emails per session"></i></h6>
                    <h4 class="card-text font-weight-bolder">{{averageNumberOfOpen}}</h4>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <h6 class="card-title">Average Forwarded Emails  <i class="fa fa-info-circle" title="Average number of forwarded emails per session"></i></h6>
                    <h4 class="card-text font-weight-bolder">{{averageNumberOfForward}}</h4>

                </div>
            </div>
            <div class="card">

                <div class="card-body">
                    <h6 class="card-title">Average Deleted Emails  <i class="fa fa-info-circle" title="Average number of deleted emails per session"></i></h6>
                    <h4 class="card-text font-weight-bolder">{{averageNumberOfDelete}}</h4>
                </div>
            </div>
            <div class="card">

                <div class="card-body">
                    <h6 class="card-title">Average Replied Emails  <i class="fa fa-info-circle" title="Average number of replied emails per session"></i></h6>
                    <h4 class="card-text font-weight-bolder">{{averageNumberOfReply}}</h4>
                </div>
            </div>
        </div>
    </div>

    <!--section 3 graphs-->
    <div class="mt-5 container col-12">
        <div class="row">

            <div class="col-6 col-md-6">
                <h6 class=" font-weight-bolder"> Total By Email Engagement Metrics</h6>
                <charts class="card" :seriesData="mainChart"/>
            </div>
                <div class="col-6 col-md-6">
                    <h6 class=" font-weight-bolder"> Total By Email Engagement Metrics & Behavior</h6>
                    <chartsTwo class="card" :seriesData="mainAverageChart"  :filters="filters" :filteredData="filteredData" />
                </div>

            <div class="col-6 col-md-6 mt-5">
                <h6 class=" font-weight-bolder"> Average By Email Engagement Metrics</h6>
                <charts3 class="card" :seriesData="subChart"/>
            </div>
            <div class="col-6 col-md-6 mt-5">
                <h6 class=" font-weight-bolder"> Average By Email Engagement Metrics & Behavior</h6>
                <charts4 class="card" :seriesData="subAverageChart" :filters="filters" :filteredDataTwo="filteredDataTwo" />
            </div>

        </div>


    </div>


    <!--section 4 table-->
    <div class="mt-5 container col-12 pb-5">
        <h6 class=" font-weight-bolder"> Campaign Performance Table</h6>
        <div class="card">
            <table class="table">
                <thead>
                <tr>
                    <th scope="col">Campaign Name</th>
                    <th scope="col">Email Provider</th>
                    <th scope="col">Opened</th>
                    <th scope="col">Forwarded</th>
                    <th scope="col">Replied</th>
                    <th scope="col">Deleted</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(link, index) in tableData" :key="index">
                    <td>{{link.campaign_name}}</td>
                    <td>{{link.isp}}</td>
                    <td>{{link.numberOfRead}}</td>
                    <td>{{link.numberOfForward}}</td>
                    <td>{{link.numberOfReply}}</td>
                    <td>{{link.numberOfDelete}}</td>
                </tr>
                </tbody>
            </table>
        </div>

    </div>
    </section>
</div>
</template>

<script>
import moment from "moment-timezone";
import axios from "axios";
// import swal from "sweetalert";
import charts from "@/components/dashboard/inboxEnhanced/components/graphs/main";
import chartsTwo from "@/components/dashboard/inboxEnhanced/components/graphs/mainTwo";
import charts3 from "@/components/dashboard/inboxEnhanced/components/graphs/main3";
import charts4 from "@/components/dashboard/inboxEnhanced/components/graphs/main4";
import swal from "sweetalert";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {createSpinner, hideSpinner, showSpinner} from "@syncfusion/ej2-vue-popups";
export default {
    name: "main",
    components: {
        charts,
        chartsTwo,
        charts3,
        charts4
    },
    data: function () {
        return {
            loading: false,
            data: null,
            clients: [],
            product_type: 'inboxEnhanced',
            filters: {
                start: '',
                end: '',
                product_type: 'inboxEnhanced',
                client: '',
                campaign:'',
                timePeriod:'',
                hourly: false,
                chart2:'y',
                chart4:'y',
            },
            products: [
                { key: "adverifier", value: "Ad Verifier" },
                { key: "inboxEnhanced", value: "Inbox Enhanced" },
            ],
            averageNumberOfDelete: 0,
            averageNumberOfForward: 0,
            averageNumberOfJunk: 0,
            averageNumberOfOpen: 0,
            averageNumberOfReply: 0,
            totalNumberOfDelete: 0,
            totalNumberOfForward: 0,
            totalNumberOfJunk: 0,
            totalNumberOfOpen: 0,
            totalNumberOfReply: 0,
            mainChart:[],
            subChart:[],
            filteredData:[],
            filteredDataTwo:[],
            mainAverageChart:[],
            subAverageChart:[],
            tableData:[],
            dropdownClients: [],
            dropdownProducts: [],
            dropdownCampaigns: [],
            pageSettings: {
                pageSize: 25,
                pageCount: 10,
                pageSizes: [25, 50, 100, 250, 500, 1000]
            },
        };
    },
    mounted: function () {
        // this.$root.preloader = false;
        this.GetClientsList();
        // this.GetCampaignList();
        createSpinner({target: document.getElementById('container')});
        createSpinner({target: document.getElementById('container1')});
        createSpinner({target: document.getElementById('container3')});
        createSpinner({target: document.getElementById('container4')});
    },
    created() {
        let start = moment().startOf("month").toDate();
        let end = moment().endOf('month').toDate();
        this.filters.start = this.getDateFormatted(start);
        this.filters.end = this.getDateFormatted(end);
    },
    beforeDestroy() {},

    methods: {
        parseDateString(dateStr) {
            const monthMap = {
                Jan: 0, Feb: 1, Mar: 2, Apr: 3, May: 4, Jun: 5,
                Jul: 6, Aug: 7, Sep: 8, Oct: 9, Nov: 10, Dec: 11
            };

            const [day, month] = dateStr.split(' ');
            const dateObj = new Date(2024, monthMap[month], parseInt(day));

            // Return timestamp for direct comparison
            return dateObj.getTime();
        },
        parseXValue(x) {
         // Check for Date
        if (/\d{4}-\d{2}-\d{2}/.test(x)) {
            return new Date(x).getTime();
        }

        // Check for Year
        if (/^\d{4}$/.test(x)) {
            return parseInt(x, 10) * 1e10;
        }

        // Check for Quarter
        if (/^Q\d \d{4}$/.test(x)) {
            const [quarter, year] = x.split(' ');
            return parseInt(year, 10) * 1e10 + parseInt(quarter[1], 10) * 1e9;
        }

        // Check for Month
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        if (months.includes(x)) {
            return months.indexOf(x) * 1e9;
        }

        // Check for Week
        if (/^\d+ Week$/.test(x)) {
            return parseInt(x.split(' ')[0], 10) * 1e7;
        }

        // Check for 24-Hour Time (0 - 23)
        if (/^\d{1,2}$/.test(x) && parseInt(x, 10) >= 0 && parseInt(x, 10) <= 23) {
            return parseInt(x, 10) * 1e5;
        }

        // Default (leave as-is for unsupported formats)
            const monthMap = {
                Jan: 0, Feb: 1, Mar: 2, Apr: 3, May: 4, Jun: 5,
                Jul: 6, Aug: 7, Sep: 8, Oct: 9, Nov: 10, Dec: 11
            };

            const [day, month] = x.split(' ');
            const dateObj = new Date(2024, monthMap[month], parseInt(day));

            // Return timestamp for direct comparison
            return dateObj.getTime() || x;
        // return x;
         },
        exportToPDF() {
            // const contentToCapture = this.$refs.contentToCapture;
            //
            // html2canvas(contentToCapture).then((canvas) => {
            //     const imgData = canvas.toDataURL("image/png");
            //     const pdf = new jsPDF();
            //     pdf.addImage(imgData, "PNG", 0, 0);
            //     pdf.save("download.pdf");
            // });
            const doc =  new jsPDF("p", "px", [this.$refs.contentToCapture.clientWidth, this.$refs.contentToCapture.clientHeight]);
            //     new jsPDF({
            //     orientation: 'p',
            //     unit: 'px',
            //     format: 'a4',
            //     hotfixes: ['px_scaling'],
            // });


            html2canvas(this.$refs.contentToCapture, {
                width: this.$refs.contentToCapture.clientWidth,
                height: this.$refs.contentToCapture.clientHeight
            }).then((canvas) => {
                const img = canvas.toDataURL("image/png");

                doc.addImage(img, "PNG", 0, 0, this.$refs.contentToCapture.clientWidth, this.$refs.contentToCapture.clientHeight);
                doc.save("p&lstatement.pdf");
            })
        },
        getDateFormatted: function(d){
            var
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;
            return [year, month, day].join('-');
        },
        async search(){

            try
            {
                showSpinner(document.getElementById('container'));
                showSpinner(document.getElementById('container1'));
                showSpinner(document.getElementById('container3'));
                showSpinner(document.getElementById('container4'));
                this.searching = true;
                this.showExportButton = false;
                this.reportLoading = true;
                this.reports = [];


                const request = {'apikey': this.$root.apikey, sSearch:null, filters:{}};
                if(this.filters.client) request.filters.client = this.filters.client;
                if(this.filters.campaign) request.filters.campaign = this.filters.campaign;
                if(this.filters.end) request.filters.end = this.filters.end;
                if(this.filters.start) request.filters.start = this.filters.start;
                if(this.filters.timePeriod) request.filters.timePeriod = this.filters.timePeriod;
                request.user = this.$store.state.user
                request.filters.chart2 = this.filters.chart2;
                request.filters.chart4 = this.filters.chart4;
                request.filters.singleRequest = false
                request.filters.singleRequestchart2 = false

                const response = await axios.get(`${this.$root.serverUrl}/static/dashboard/overviewSummary`,{params:request});
                if(response.data.error){
                    this.reportLoading = false;
                    swal({title:'Oops', text: `${response.data.error.message}`, icon: 'error'})}
                else {

                        this.averageNumberOfDelete = response.data.Items.length > 0 ?  response.data.Items[0].averageNumberOfDelete : 0
                        this.averageNumberOfForward =  response.data.Items.length > 0 ?  response.data.Items[0].averageNumberOfForward : 0
                        this.averageNumberOfJunk =  response.data.Items.length > 0 ?  response.data.Items[0].averageNumberOfJunk : 0
                        this.averageNumberOfOpen =  response.data.Items.length > 0 ?  response.data.Items[0].averageNumberOfOpen : 0
                        this.averageNumberOfReply =  response.data.Items.length > 0 ?  response.data.Items[0].averageNumberOfReply : 0
                        this.totalNumberOfDelete =  response.data.Items.length > 0 ?  response.data.Items[0].totalNumberOfDelete : 0
                        this.totalNumberOfForward =  response.data.Items.length > 0 ?  response.data.Items[0].totalNumberOfForward : 0
                        this.totalNumberOfJunk =  response.data.Items.length > 0 ?  response.data.Items[0].totalNumberOfJunk : 0
                        this.totalNumberOfOpen =  response.data.Items.length > 0 ?  response.data.Items[0].totalNumberOfOpen : 0
                        this.totalNumberOfReply =  response.data.Items.length > 0 ?  response.data.Items[0].totalNumberOfReply : 0


                }
                const mainChart = await axios.get(`${this.$root.serverUrl}/static/dashboard/overviewChart`,{params:request});


                if(mainChart.data.error){
                    this.reportLoading = false;
                    hideSpinner(document.getElementById('container'));
                    hideSpinner(document.getElementById('container3'));
                    swal({title:'Oops', text: `${mainChart.data.error.message}`, icon: 'error'})}
                else {
                    this.mainChart = mainChart.data.Items.mainChart.sort((a, b) => {
                        return this.parseXValue(a.x) - this.parseXValue(b.x);
                    });

                    this.subChart = mainChart.data.Items.subChart.sort((a, b) => {
                        return this.parseXValue(a.x) - this.parseXValue(b.x);
                    });

                    hideSpinner(document.getElementById('container'));
                    hideSpinner(document.getElementById('container3'));
                }


                const mainAverageChart = await axios.get(`${this.$root.serverUrl}/static/dashboard/overviewChartAverage`,{params:request});


                if(mainAverageChart.data.error){
                    this.reportLoading = false;
                    hideSpinner(document.getElementById('container1'));
                    hideSpinner(document.getElementById('container4'));
                    swal({title:'Oops', text: `${mainAverageChart.data.error.message}`, icon: 'error'})}
                else {
                    this.mainAverageChart = mainAverageChart.data.Items.mainAverageChart.sort((a, b) => {
                        return this.parseXValue(a.x) - this.parseXValue(b.x);
                    });
                    this.filteredData = this.mainAverageChart;
                    this.subAverageChart = mainAverageChart.data.Items.subAverageChart.sort((a, b) => {
                        return this.parseXValue(a.x) - this.parseXValue(b.x);
                    });
                    this.filteredDataTwo = this.subAverageChart;
                    hideSpinner(document.getElementById('container1'));
                    hideSpinner(document.getElementById('container4'));
                }

                const table =await axios.get(`${this.$root.serverUrl}/static/dashboard/inboxWarmerCampaign`,{params:request});

                if(table.data.error){
                    this.reportLoading = false;
                    swal({title:'Oops', text: `${table.data.error.message}`, icon: 'error'})}
                else {
                    this.tableData = table.data.Items
                    console.log('123q23', this.tableData)
                }
                    //inboxWarmerCampaign
            }catch (e) {
                console.log(e);
                this.reportLoading = false;
                this.searching = false;
                swal({title:'Oops', text: `Sorry, I am unable to complete your request. ${e.message}`, icon: 'error'})
            }
        },
        formatDate(date) {
            let hours = date.getHours().toString().padStart(2, '0');
            let minutes = date.getMinutes().toString().padStart(2, '0');
            let seconds = date.getSeconds().toString().padStart(2, '0');
            return hours + minutes + seconds;
        },
        GetClientsList: async function(){
            var request = {'apikey': this.$root.apikey};


            let dataOne = await axios.get(`${this.$root.serverUrl}/admin/dropdown/client`, {params:request});
            let dataTwo = await axios.get(`${this.$root.serverUrl}/admin/dropdown/product/inboxEnhancedCamapigns`, {params:request});
            this.dropdownClients = (dataOne.data)? dataOne.data.data : [];
            this.dropdownCampaigns = (dataTwo.data && dataTwo.data.campaigns)? dataTwo.data.campaigns : [];

        },


    },
}
</script>

<style scoped>

</style>
