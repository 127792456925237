<template>
    <div id="app">
        <ejs-grid :dataSource="getData" :allowResizing='true'   height='432'  :gridLines='lines' >
            <e-columns class="e-headercells">
                <e-column field='Hours' headerText='Hours' textAlign='left' width=180 height=500></e-column>
                <e-column field='Impression' headerText='Impression' width=100></e-column>
                <e-column field='Goals' headerText='Goals' width=75></e-column>
            </e-columns>
        </ejs-grid>
    </div>
</template>
<script>
import Vue from "vue";
import { GridPlugin, Resize } from "@syncfusion/ej2-vue-grids";

Vue.use(GridPlugin);

export default {
    name:'forecasted',
    data() {
        return {

            lines: 'Both'
        };
    },
    provide: {
        grid: [Resize]
    },
    computed: {
        getData() {
            var data = [];
            data = this.$store.state ? this.$store.state.forcated : data;
            return data;
        },
    },
}
</script>
<style >
/*.e-grid .e-headercells {*/
/*    background-color: gray!important;*/
/*}*/
@import "../../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
/*@import "../../node_modules/@syncfusion/ej2-buttons/styles/material.css";*/
/*@import "../../node_modules/@syncfusion/ej2-vue-inputs/styles/material.css";*/
.e-grid .e-headercells {
    background-color: #e7c5fa!important;
    border-color: #dee2e6!important;
    border-top-color: rgb(222, 226, 230)!important;
}
</style>
