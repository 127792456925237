<template>
    <div class="row">
        <div class="col-6">
            <!-- <b-form-select :options="providers" v-model="filter.provider"  /> -->
        </div>
        <div class="col-6">
            <b-form-select :options="times" v-model="filter.time" @input="Handle"  />
        </div>
    </div>
</template>

<script>
import moment from "moment-timezone";
export default {
    name: "perImpression",
    props:['time','handle'],
    data() {
        return {
            filter: { provider: 'all', time: `${moment()}` },
            providers: [ { value: 'all', text: 'All Providers' } ],
            times: [ 
                { value: `${moment()}`, text: '24hrs' },
                { value: `${moment().add(-7,'days')}`, text: '7Days' },
                // { value: `${((24*7)*4)*1}`, text: 'Month' },
                // { value: `${((24*7)*4)*3}`, text: '3Month' },
                { value: `${moment().add(-6,'months')}`, text: '6Month' },
            ],
        }
    },
    computed: {
        getTime(){
            let data = this.filter.time
            return data;
        }
    },
    methods: {
        Handle(){
            if(this.handle){
                this.handle(this.filter.time)
            }
        }
    },
}
</script>

<style scoped>

</style>
