<template>
    <div>
    <div style="background-color:white; padding:2%;" class="mb-3">


        <!-- Chart -->
        <div class="row mb-3">
            <div class="col-12">
<!--                <div class="container-fluid">-->
                    <div class="row">
                        <div class="col-md-12">
                            <div class="d-flex justify-content-end">
                                <div class="btn-group justify-content-end">
<!--                                    <div class="d-flex align-items-center">-->
<!--                                        <button class="btn btn-sm btn-light">Export Grid</button>-->
<!--                                    </div>-->
                                    <div class="d-flex align-items-center ml-2">
                                        <b-dropdown
                                            text="Product Selection"
                                            variant="light"
                                            size="sm"
                                            v-b-toggle.responsiveDropdown
                                        >
                                            <b-dropdown-item @click="handleItemClick('domain')" :class="{ 'text-success': product == 'domain' }">View Ad verifier</b-dropdown-item>
                                            <b-dropdown-item @click="handleItemClick('email')" :class="{ 'text-success': product == 'email' }">View Email Verifier</b-dropdown-item>
                                        </b-dropdown>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
<!--                </div>-->




                <!--                <div class=" d-flex justify-content-end align-content-end pb-2">-->

<!--                <b-dropdown  text="Product Selection"-->
<!--                             variant="light"-->
<!--                             class="ml-auto "-->
<!--                             size="sm"-->
<!--                             v-b-toggle.responsiveDropdown>-->

<!--                    <b-dropdown-item @click="handleItemClick('domain')" :class="{ 'text-success': product == 'domain' }">View Ev</b-dropdown-item>-->
<!--                    <b-dropdown-item @click="handleItemClick('email')"   :class="{ 'text-success': product == 'email' }">View Adverifier</b-dropdown-item>-->
<!--                </b-dropdown>-->

<!--                </div>-->



                <mainbar :shownRate = 'shownrate' :isLoading ='isLoading'  />
<!--                    </div>-->
<!--                <charts-css-->
<!--                    id="shownrate"-->
<!--                    heading="Shown Rate"-->
<!--                    :type="isMobile()? 'bar':'column'"-->
<!--                    :labels="shownrate.labels"-->
<!--                    :datasets="shownrate.values"-->
<!--                    :show-data-axis="true"-->
<!--                    :show-legend="false"-->
<!--                    :show-heading="false"-->
<!--                    :show-labels="true"-->
<!--                    :legend-inline="true"-->
<!--                    legend-type="ellipse"-->
<!--                    :data-spacing="2"-->
<!--                    :show-data-on-hover="false"-->
<!--                    :stacked="true"-->
<!--                />-->
            </div>
        </div>
        <!-- Chart End -->
    </div>
        <div class="row mb-5">

            <qso :hits='hits' class="col-6 col-md-3 mb-3"/>
            <div class="col-6 col-md-3 mb-3">
                <div class="card">
                    <div class="card-body text-center">
                        <h4>{{ weekly.Total || 0 }}</h4>
                        <h6>This Weeks Total</h6>
                    </div>
                </div>
            </div>
            <div class="col-6 col-md-3 mb-3">
                <div class="card">
                    <div class="card-body text-center">
                        <h4 v-if="weekly.Total">$ {{ ((weekly && weekly.Total && weekly.Total.length > 0 ? Number(weekly.Total.replaceAll(/,/g, '')) : 0) * .02).toLocaleString('en') || 0 }}</h4>
                        <h6>Revenue</h6>
                    </div>
                </div>
            </div>

            <incidentWidget class="col-6 col-md-3 mb-3" :user ='user'/>
        </div>

        <div class="row">

                    <!-- <statebar :title='`This is title for state`' class="col-12 ml-1 mb-3 pl-2"/> -->
                    <!-- <proxyband class="col-12 ml-1 mb-3 pl-2"/> -->
                    <!-- <clicksneeded class="col-12 ml-1 mb-3 pl-2"/> -->
            <div class="col-12 col-sm-2 mb-3">
                <h6 class="border-bottom pb-2 mb-3">
                    <i class="fa fa-stack-exchange"></i> Calculated Summary
                </h6>
                <div class="row">
                    <div class="col-12 mb-2">
                        <div class="card">
                            <div class="card-body text-center">
                                <div v-if="!loadActive">
                                    <h4>{{Number(calculatedShown.percentage||0).toFixed(2)}}%</h4>
                                    <h6>Shown Rate</h6>
                                </div>
                                <h4 v-if="loadActive"> <img src="/25.gif"/></h4>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 mb-2">
                        <div class="card">
                            <div class="card-body text-center">
                                <div v-if="!loadActive">
                                    <h4>{{Number(calculatedShown.shown||0).toLocaleString()}}</h4>
                                    <h6>Shown</h6>
                                </div>
                                <h4 v-if="loadActive"> <img src="/25.gif"/></h4>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 mb-2">
                        <div class="card">
                            <div class="card-body text-center">
                                <div v-if="!loadActive">
                                    <h4>{{Number(calculatedShown.capped||0).toLocaleString()}}</h4>
                                    <h6>Capped</h6>
                                </div>
                                <h4 v-if="loadActive"> <img src="/25.gif"/></h4>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 mb-2">
                        <div class="card">
                            <div class="card-body text-center">
                                <div v-if="!loadActive">
                                    <h4>{{Number(calculatedShown.total||0).toLocaleString()}}</h4>
                                    <h6>Total</h6>
                                </div>
                                <h4 v-if="loadActive"> <img src="/25.gif"/></h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 col-sm-2 mb-3">
                <h6 class="border-bottom pb-2 mb-3">
                    <i class="fa fa-stack-exchange"></i> Performance Summary
                </h6>
                <div class="row">
                    <div class="col-12 mb-2">
                        <div class="card">
                            <div class="card-body text-center">
                                <div v-if="!loadActive">
                                    <h4>{{Number(summary.active||0).toLocaleString()}}</h4>
                                    <h6>Active Workers</h6>
                                </div>
                                <h4 v-if="loadActive"> <img src="/25.gif"/></h4>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 mb-2">
                        <div class="card">
                            <div class="card-body text-center">
                                <div v-if="!loadActive">
                                    <h4>{{Number(summary.total||0).toLocaleString()}}</h4>
                                    <h6>Total Workers</h6>
                                </div>
                                <h4 v-if="loadActive"> <img src="/25.gif"/></h4>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 mb-2">
                        <div class="card">
                            <div class="card-body text-center">
                                <div v-if="!loadActive">
                                    <h4>{{Number(summary.hour||0).toLocaleString()}}</h4>
                                    <h6>Current Hour</h6>
                                </div>
                                <h4 v-if="loadActive"> <img src="/25.gif"/></h4>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 mb-2">
                        <div class="card">
                            <div class="card-body text-center">
                                <div v-if="!loadActive">
                                    <h4>{{Number(summary.performance||0).toLocaleString()}}</h4>
                                    <h6>Performance</h6>
                                </div>
                                <h4 v-if="loadActive"> <img src="/25.gif"/></h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 col-sm-2 mb-3">
                <h6 class="border-bottom pb-2 mb-3">
                    <i class="fa fa-stack-exchange"></i> Bandwidth Usage
                </h6>
                <div class="row">
                    <div class="col-12 mb-2">
                        <div class="card">
                            <div class="card-body text-center">
                                <div v-if="!loadActive">
                                    <h4>{{Number(Number(bandwidth.download||0) / 1024/1024/1024/1000).toLocaleString()}} TB</h4>
                                    <h6>Download</h6>
                                </div>
                                <h5 v-if="loadActive"> <img src="/25.gif"/></h5>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 mb-2">
                        <div class="card">
                            <div class="card-body text-center">
                                <div v-if="!loadActive">
                                    <h4>{{Number(Number(bandwidth.upload||0) / 1024/1024/1024/1000).toLocaleString()}} TB</h4>
                                    <h6>Upload</h6>
                                </div>
                                <h4 v-if="loadActive"> <img src="/25.gif"/></h4>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 mb-2">
                        <div class="card">
                            <div class="card-body text-center">
                                <div v-if="!loadActive">
                                    <h4>{{Number(Number(bandwidth.total||0) / 1024/1024/1024/1000).toLocaleString()}} TB</h4>
                                    <h6>Total Usage</h6>
                                </div>
                                <h4 v-if="loadActive"> <img src="/25.gif"/></h4>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 mb-2">
                        <div class="card">
                            <div class="card-body text-center">
                                <div v-if="!loadActive">
                                    <h4>{{Number(bandwidth.requests||0).toLocaleString()}}</h4>
                                    <h6>Requests</h6>
                                </div>
                                <h4 v-if="loadActive"> <img src="/25.gif"/></h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--<div class="col-12 col-sm-4 mb-3">
                <h6 class="border-bottom pb-2 mb-3">
                    <i class="fa fa-stack-exchange"></i> SI Vendor Summary
                    <button type="button" class="btn btn-primary btn-sm mr-2" @click="loadVendors"><i class="fa fa-refresh"></i> Refresh</button>
                </h6>

                <div v-if="!loadData2">
                    <ul class="shownvendors list-group list-group-flush">
                        <li class="list-group-item d-flex justify-content-between align-items-center" v-for="(data, idx) in vendor.data.rows" v-bind:key="data._id" :class="{active: idx == 0}">
                            <div>{{data.TrafficSource}}</div>
                            <div><span class="badge badge-primary font-weight-bold">#{{data.Total||0}}</span></div>
                            <div><span class="badge badge-primary font-weight-bold">{{data.S||0}}</span></div>
                        </li>
                    </ul>
                </div>
            </div>-->

            <div class="col-12 col-sm-6 mb-3">
                <h6 class="border-bottom pb-2 mb-3">
                    <i class="fa fa-stack-exchange"></i> Shown Summary
                    <span v-if="!isMobile()">
                        <button type="button" class="btn btn-sm btn-primary mr-2" @click="load"><i class="fa fa-refresh"></i> Refresh</button>
                        <button type="button" @click="hourlyReportModal = true" class="btn btn-sm mr-2" style="background-color: #FF8C00">View 3 Hour Report</button>
                        <button type="button" @click="$router.push('/capped-and-shown-report')" class="btn btn-sm" style="background-color: #FF6347">View Report</button>
                    </span>
                </h6>
                <div class="mb-3" v-if="isMobile()">
                    <button type="button" class="btn btn-sm btn-primary mr-2" @click="load"><i class="fa fa-refresh"></i> Refresh</button>
                    <button type="button" @click="hourlyReportModal = true " class="btn btn-sm mr-2" style="background-color: #FF8C00">3 Hour Report</button>
                    <button type="button" @click="$router.push('/capped-and-shown-report')" class="btn btn-sm" style="background-color: #FF6347">View Report</button>
                </div>
                <div v-if="!loadData">
                    <ul class="shownsummary list-group list-group-flush">
                        <li class="list-group-item d-flex justify-content-between align-items-center" v-for="(data, idx) in shown.data" v-bind:key="data._id" :class="{active: idx == 0}">
                            <div>Total <span class="badge badge-primary font-weight-bold">{{data.data.summary.Total}}</span></div>
                            <div>Shown <span class="badge badge-primary font-weight-bold">{{data.data.summary.S}}</span></div>
                            <div><span class="font-weight-bold"><button type="button" class="btn btn-primary" @click="load_campData(data.data.rows,data.data.summary)"  data-toggle="modal" data-target="#exampleModal">
                                Details
                            </button></span></div>
                            <div class="time">{{new Date(data.created_ts).toLocaleTimeString()}}</div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <!-- <div class="row mb-4">
            <div class="col-12">
                <h5 class="border-bottom pb-2 mb-3">
                    <i class="fa fa-signal"></i> E-Campaign Check Reporting
                    <button class="pull-right btn btn-sm" type="button" @click="showECampaignsRep = !showECampaignsRep">
                        <i class="fa" :class="{'fa-minus-square-o':showECampaignsRep, 'fa-plus-square-o': !showECampaignsRep}"></i>
                    </button>
                </h5>
            </div>

            <div v-if="showECampaignsRep" class="mt-4 col-12">
                <hr>
                <div class="row mb-4">
                    <div class="col-12 text-right">
                        <div class="form-inline">
                            <b-input-group class="mr-4">
                                <b-form-input v-model="filterType"></b-form-input>
                                <template #append>
                                    <b-dropdown text="Filter" variant="success">
                                        <b-dropdown-item @click="setFilter('today')">Today</b-dropdown-item>
                                        <b-dropdown-item @click="setFilter('yesterday')">Yesterday</b-dropdown-item>
                                        <b-dropdown-item @click="setFilter('2daysago')">2 Days Ago</b-dropdown-item>
                                    </b-dropdown>
                                    <b-button style="background-color: #383838" @click="filterECampaigns"><i class="fa fa-search"></i> Search</b-button>
                                </template>
                            </b-input-group>
                        </div>
                    </div>
                </div>
                <div class="row mb-12" v-if="ecampaignLoading">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body text-center">
                                <b-spinner style="width: 3rem; height: 3rem;" type="grow" label="Processing......" variant="primary"></b-spinner>
                                <h4>Processing..............</h4>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-12" v-else>
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body text-center">
                                <div class="row mb-12">
                                    <div class="col-12 m-table">
                                        <h3 v-if="searched">Missing Campaigns ({{ this.missedTotal }}) Missed Clicks ({{ this.missedClicks }})</h3>
                                        <h3 v-else>Missing Campaigns/Missed Clicks Summary <small>(select a filter and search to generate results)</small></h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>-->

        <div class="row">
            <div class="col-6 col-sm-6 col-md-6">
              <!--  <v-pagination
                    v-model="page"
                    :pageCount="Math.ceil(stats.campaigns.total/max)"
                    :classes="bootstrapPaginationClasses"
                    :labels="customLabels"
                    @input="loadStats"></v-pagination>-->
            </div>
            <div class="col-6 col-sm-6 col-md-6 text-right">
                <!--<span>{{stats.campaigns.total * Number(page|1)}}</span> of <span>{{stats.campaigns.total}}</span>-->
            </div>
        </div>

        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Campaign Details - Summary Total: {{campStat.Total}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-sm-12">
                                    <table class="table table-hover table-responsive bg-light text-dark">
                            <thead>
                            <tr>
                                <td>TrafficLinkID</td>
                                <td>Total</td>
                                <td>Matched</td>
                                <td>M %</td>
                                <td>Shown</td>
                                <td>S %</td>
                                <td>Capped</td>
                                <td>C %</td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="data in camp" v-bind:key="data._id">
                                <td>{{data.TrafficLinkID}}</td>
                                <td>{{data.Total}}</td>
                                <td>{{data.Matched}}</td>
                                <td>{{data.M}}</td>
                                <td>{{data.Shown}}</td>
                                <td>{{data.S}}</td>
                                <td>{{data.Capped}}</td>
                                <td>{{data.C}}</td>
                            </tr>
                            </tbody>
                        </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>

        <b-modal v-model="hourlyReportModal" title="Hourly Report Summary" size="lg">
            <table class="table table-responsive table-hover bg-light text-dark">
                <thead>
                <tr>
                    <th>Time Frame</th>
                    <th>Total</th>
                    <th>Matched</th>
                    <th>M %</th>
                    <th>Shown</th>
                    <th>S %</th>
                    <th>Capped</th>
                    <th>C %</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="data in hourlyReportData" v-bind:key="data._id">
                    <td>{{ data.Timeframe }}</td>
                    <td>{{data.data.summary.Total}}</td>
                    <td>{{data.data.summary.Matched}}</td>
                    <td>{{data.data.summary.M}}</td>
                    <td>{{data.data.summary.Shown}}</td>
                    <td>{{data.data.summary.S}}</td>
                    <td>{{data.data.summary.Capped}}</td>
                    <td>{{data.data.summary.C}}</td>
                </tr>
                </tbody>
            </table>
            <!---
                <template>
                    <b-button size="sm" variant="primary" @click="hourlyReportModal = false">
                        OK
                    </b-button>
                </template>
            -->
        </b-modal>
    </div>
</template>

<script>
    import axios from 'axios'
    import swal from "sweetalert";
    // import filters from '../filters'
    import qso from "@/components/shared/qso";
    import incidentWidget from "@/components/shared/incidents";
    import mainbar from "./mainBar";

// import statebar from "@/components/monitoring/statebar";
// import clicksneeded from "@/components/shared/clicksneeded";
// import proxyband from "@/components/shared/proxyband";

    //import vPagination from 'vue-plain-pagination'
    export default {
        name: 'Dashboard',
        props:['user'],
        components: {
            //vPagination
            qso,
            mainbar,
            incidentWidget
            // clicksneeded,
            // statebar,
            // proxyband
        },
        data: function () {
            return {
                // errorCounter: 0,
                hits:null,
                text: '',
                errorCounter: null,
                counter:{counter:0},
                showndata: null,
                shownrate: {labels:[], values:[]},
                showCampaigns: true,
                showECampaignsRep: true,
                showNodes: true,
                summary: {active:0, total:0, performance:0, hour:0},
                stats:{},
                weekly:{},
                refreshData:'off',
                stat:{},
                status:{active:'active', pending:'pending',done:'done',proxy:'proxy'},
                campStat:{},
                camp:{},
                shown:{},
                vendor:{},
                campaignsList:[],
                hourlyReportData: [],
                hourlyReportModal: false,
                loadData:false,
                loadData2:false,
                loadActive:false,
                loadProxy:false,
                loadPending:false,
                loadDone:false,
                timer:true,
                max: 102,
                page: 0,
                sort:{
                    name:'',
                    daily:'',
                    max:'',
                    goodHits:'',
                    badHits:'',
                    filterType:''
                },
                bootstrapPaginationClasses: {
                    ul: 'pagination',
                    li: 'page-item',
                    liActive: 'active',
                    liDisable: 'disabled',
                    button: 'page-link'
                },
                customLabels: {
                    first: 'First',
                    prev: 'Previous',
                    next: 'Next',
                    last: 'Last'
                },
                filterType: null,
                searched: false,
                missedTotal:0,
                missedClicks:0,
                ecampaignLoading: false,
                bandwidth: {total: 0, download: 0, upload: 0, requests: 0},
                eventBandwidth: null,
                eventShown: null,
                eventweekly: null,
                eventWorkers: null,
                eventHits:null,
                calculatedShown: {total:0, capped:0, shown: 0, percentage: 0, length:0},
                product:'domain',
                isLoading: false,
                eventSources:{},
            }
        },
        computed: {
            shownType(){
                let mobile = this.isMobile();
                return mobile? "bar" : "column";
            },
            fields() {
                return [
                    {
                        key: 'TrafficSource',
                        label: 'TrafficSource',
                        sortable: true
                    },{
                        key: 'Total',
                        label: 'Total',
                        sortable: true
                    },{
                        key: 'Matched',
                        label: 'Matched',
                        sortable: true
                    },{
                        key: 'M',
                        label: 'M %',
                        sortable: true
                    },{
                        key: 'Shown',
                        label: 'Shown',
                        sortable: true
                    },{
                        key: 'S',
                        label: 'S %',
                        sortable: true
                    },{
                        key: 'Capped',
                        label: 'Capped',
                        sortable: true
                    },{
                        key: 'C',
                        label: 'C %',
                        sortable: true
                    },{
                        key: 'created_ts',
                        label: 'Date',
                        sortable: true
                    },
                ]
            },
            convertVender() {
                let prev = []
                if( this.vendor != undefined){
                    this.vendor.data.rows.forEach( ( curr ) => {

                        let obj = {
                            C: curr.C,
                            Capped: parseInt( curr.Capped.replace(/,/g, ''), 10 ),
                            M: curr.M,
                            Matched: parseInt( curr.Matched.replace(/,/g, ''), 10 ),
                            S: curr.S,
                            Shown: parseInt( curr.Shown.replace(/,/g, ''), 10 ),
                            Total: parseInt( curr.Total.replace(/,/g, ''), 10 ),
                            TrafficSource: curr.TrafficSource,
                            Created_ts : this.vendor.created_ts

                        }
                        prev.push(obj)
                    })
                }


                return prev
            },
            // getIncidents() {
            //     let data = this.counter.counter;
            //     return data;
            // },
        },
        created: async function() {
            this.loadWeekly();
            this.loadStats();
            this.timezoneCheck();
            this.loadStats();
            //this.loadCampaignStats();
            await this.load();
            await this.hourlyReport();
            this.setupEventStreams();
            this.timezoneCheck();

            setInterval( this.loadWeekly, 5 * 60 * 1000);

        },
        destroyed() {
            this.closeEventStreams();
        },
        // created: async function(){
        //     this.timezoneCheck();
        //     this.loadStats();
        //     //this.loadCampaignStats();
        //     await this.load();
        //     await this.hourlyReport();
        //     //this.loadVendors();
        //
        //
        //     // //Register bandwidth eventstream
        //     if(!this.eventBandwidth) {
        //         this.eventBandwidth = new EventSource(`${this.$root.serverUrl}/static/dashboard/bandwidth`, {withCredentials: false});
        //
        //         this.eventBandwidth.onerror = (error) => {
        //             console.log(`eventBandwidth.error`, error);
        //         }
        //         this.eventBandwidth.onopen = (args) => {
        //             console.log(`eventBandwidth.open`, args);
        //         }
        //         this.eventBandwidth.onmessage = (event) => {
        //             //console.log(`eventBandwidth.event`, event);
        //             try {
        //                 const parsedData = JSON.parse(event.data);
        //                 this.bandwidth = Object.assign({total: 0, download: 0, upload: 0, requests: 0}, parsedData);
        //                 this.$forceUpdate();
        //             } catch (e) {
        //                 console.log(`eventBandwidth.catch: ${e.message}`);
        //             }
        //         };
        //     }
        //
        //     //Register shown eventstream
        //     if(!this.eventShown) {
        //         this.eventShown = new EventSource(`${this.$root.serverUrl}/static/dashboard/shown`, {withCredentials: false});
        //
        //         this.eventShown.onerror = (error) => {
        //             console.log(`eventShown.error`, error);
        //         }
        //         this.eventShown.onopen = (args) => {
        //             console.log(`eventShown.open`, args);
        //         }
        //         this.eventShown.onmessage = (event) => {
        //             //console.log(`eventShown.event`, event);
        //             try {
        //                 const parsedData = JSON.parse(event.data);
        //
        //                 //Process the shown data
        //                 if(parsedData && parsedData.length){
        //                     this.shownrate.labels = [];
        //                     this.shownrate.values = [];
        //                     let values = [];
        //                     let capped = [];
        //                     let totals = [];
        //
        //                     for(var h=0;h<24;h++){
        //                         let suffix = (h >= 12)? 'pm' : 'am';
        //                         let hours = (h > 12) ? h - 12 : h;
        //                         hours = (hours == 0) ? 12 : hours;
        //                         this.shownrate.labels[h] = `${hours} ${suffix}`;
        //                         values[h] = 0845;
        //                         capped[h] = 05037;
        //                         totals[h] = 0;
        //                     }
        //
        //                     this.calculatedShown.capped = 0;
        //                     this.calculatedShown.total = 0;
        //                     this.calculatedShown.shown = 0;
        //                     this.calculatedShown.percentage = 0;
        //                     this.calculatedShown.length = 0;
        //                     for(var r=0;r<parsedData.length;r++) {
        //                         //let suffix = (parsedData[r]._id >= 12) ? 'pm' : 'am';
        //                         let hours = (parsedData[r]._id > 12) ? parsedData[r]._id - 12 : parsedData[r]._id;
        //                         hours = (hours == 0) ? 12 : hours;
        //                         values[parsedData[r]._id] = parsedData[r].shown;
        //                         capped[parsedData[r]._id] = parsedData[r].capped;
        //                         totals[parsedData[r]._id] = parsedData[r].total;
        //                         this.calculatedShown.capped += Number(parsedData[r].capped);
        //                         this.calculatedShown.total += Number(parsedData[r].total);
        //                         this.calculatedShown.shown += Number(parsedData[r].shown);
        //                         this.calculatedShown.percentage += Number(parsedData[r].shownrate);
        //                         this.calculatedShown.length = parsedData.length;
        //                     }
        //
        //                     this.shownrate.values.push({name: "Shown", values: values});
        //                     this.shownrate.values.push({name: "Capped", values: capped});
        //                     this.shownrate.values.push({name: "Total", values: totals});
        //
        //                     this.calculatedShown.percentage = (this.calculatedShown.percentage / this.calculatedShown.length) * 100;
        //                 }
        //
        //                 this.$forceUpdate();
        //             } catch (e) {
        //                 console.log(`eventShown.catch: ${e.message}`);
        //             }
        //         };
        //     }
        //
        //     if(!this.eventweekly) {
        //         this.eventweekly = new EventSource(`${this.$root.serverUrl}/static/dashboard/weekly-shown`, {withCredentials: false});
        //
        //         this.eventweekly.onerror = (error) => {
        //             console.log(`eventweekly.error`, error);
        //         }
        //         this.eventweekly.onopen = (args) => {
        //             console.log(`eventweekly.open`, args);
        //         }
        //         this.eventweekly.onmessage = (event) => {
        //             //console.log(`eventWorkers.event`, event);
        //             try {
        //                 const parsedData = JSON.parse(event.data);
        //                 this.weekly = Object.assign(this.weekly,parsedData);
        //                 this.$forceUpdate();
        //             } catch (e) {
        //                 console.log(`eventweekly.catch: ${e.message}`);
        //             }
        //         };
        //     }
        //     //Register worker eventstream
        //     if(!this.eventWorkers) {
        //         this.eventWorkers = new EventSource(`${this.$root.serverUrl}/static/workers/stats`, {withCredentials: false});
        //
        //         this.eventWorkers.onerror = (error) => {
        //             console.log(`eventWorkers.error`, error);
        //         }
        //         this.eventWorkers.onopen = (args) => {
        //             console.log(`eventWorkers.open`, args);
        //         }
        //         this.eventWorkers.onmessage = (event) => {
        //             //console.log(`eventWorkers.event`, event);
        //             try {
        //                 const parsedData = JSON.parse(event.data);
        //                 this.summary = Object.assign({active: 0, total: 0, performance: 0, hour: 0}, parsedData);
        //                 this.$forceUpdate();
        //             } catch (e) {
        //                 console.log(`eventWorkers.catch: ${e.message}`);
        //             }
        //         };
        //     }
        //
        //     this.$root.preloader = false;
        // },
        beforeDestroy: function(){
            this.timer = false;
            clearTimeout(this.interval);
            if(this.eventBandwidth) {
                this.eventBandwidth.close();
                this.eventBandwidth = null;
            }
            if(this.eventWorkers) {
                this.eventWorkers.close();
                this.eventWorkers = null;
            }
            if(this.eventShown) {
                this.eventShown.close();
                this.eventShown = null;
            }
            if(this.eventweekly) {
                this.eventweekly.close();
                this.eventweekly = null;
            }
            if(this.errorCounter) {
                this.errorCounter.close();
                this.errorCounter = null;
            }
        },
        methods:{

             handleItemClick(item) {
                this.product = item
                 this.eventShown = this.createEventSource('/static/dashboard/shown', this.onShownEvent,true,this.product);
                 this.isLoading = true;

            },
            setupEventStreams() {
                this.eventHits = this.createEventSource('/static/dashboard/hitqso', this.onEventQSO,false,'');
                this.eventBandwidth = this.createEventSource('/static/dashboard/bandwidth', this.onBandwidthEvent,false,'');
                this.eventShown = this.createEventSource('/static/dashboard/shown', this.onShownEvent,false,this.product);
                // this.eventweekly = this.createEventSource('/static/dashboard/weekly-shown', this.onWeeklyEvent);
                this.eventWorkers = this.createEventSource('/static/workers/stats', this.onWorkersEvent,false,'');

            },

            createEventSource(url, onData,close,filters) {

                 if(filters == null || filters == undefined){
                     filters = 'domain'
                 }

                const filterParams = new URLSearchParams(filters).toString();

                if (this.eventSources[url] && close) {
                    this.eventSources[url].close();
                }
                const eventSource = new EventSource(`${this.$root.serverUrl}${url}?${filterParams}`, { withCredentials: false });

                eventSource.onerror = (error) => {
                    console.log(`${url}.error`, error);
                };
                eventSource.onopen = () => {
                    console.log(`${url}.open`);
                };
                eventSource.onmessage = (event) => {
                    onData(event);
                };
                this.eventSources[url] = eventSource;
                return eventSource;
            },

            onBandwidthEvent(event) {
                const parsedData = JSON.parse(event.data);
                this.bandwidth = Object.assign({total: 0, download: 0, upload: 0, requests: 0}, parsedData);
                this.$forceUpdate();
                // Handle bandwidth event data
            },
            onShownEvent(event) {
                // Handle shown event data
                try {
                    const parsedData = JSON.parse(event.data);

                    //Process the shown data
                    if(parsedData && parsedData.length){
                        this.shownrate.labels = [];
                        this.shownrate.values = [];
                        let values = [];
                        let capped = [];
                        let totals = [];

                        for(var h=0;h<24;h++){
                            let suffix = (h >= 12)? 'pm' : 'am';
                            let hours = (h > 12) ? h - 12 : h;
                            hours = (hours == 0) ? 12 : hours;
                            this.shownrate.labels[h] = `${hours} ${suffix}`;
                            values[h] = 0;
                            capped[h] = 0;
                            totals[h] = 0;
                        }

                        this.calculatedShown.capped = 0;
                        this.calculatedShown.total = 0;
                        this.calculatedShown.shown = 0;
                        this.calculatedShown.percentage = 0;
                        this.calculatedShown.length = 0;
                        for(var r=0;r<parsedData.length;r++) {
                            console.log(parsedData[r].product,'product')
                            console.log(this.product)
                            // if(parsedData[r].product == this.product) continue
                            //let suffix = (parsedData[r]._id >= 12) ? 'pm' : 'am';
                            let hours = (parsedData[r]._id.hour > 12) ? parsedData[r]._id.hour - 12 : parsedData[r]._id.hour;
                            hours = (hours == 0) ? 12 : hours;
                            values[parsedData[r]._id.hour] = parsedData[r].shown;
                            capped[parsedData[r]._id.hour] = parsedData[r].capped;
                            totals[parsedData[r]._id.hour] = parsedData[r].total;
                            this.calculatedShown.capped += Number(parsedData[r].capped);
                            this.calculatedShown.total += Number(parsedData[r].total);
                            this.calculatedShown.shown += Number(parsedData[r].shown);
                            this.calculatedShown.percentage += Number(parsedData[r].shownrate);
                            this.calculatedShown.length = parsedData.length;
                        }

                        this.shownrate.values.push({name: "Shown", values: values});
                        this.shownrate.values.push({name: "Capped", values: capped});
                        this.shownrate.values.push({name: "Total", values: totals});

                        this.calculatedShown.percentage = (this.calculatedShown.percentage / this.calculatedShown.length) * 100;
                    }
                    this.isLoading = false;
                    this.$forceUpdate();
                } catch (e) {
                    console.log(`eventShown.catch: ${e.message}`);
                }
            },
            onWeeklyEvent(event) {
                // Handle weekly event data
                const parsedData = JSON.parse(event.data);
                this.weekly = Object.assign(this.weekly,parsedData);
                this.$forceUpdate();
            },
            onWorkersEvent(event) {
                // Handle workers event data
                const parsedData = JSON.parse(event.data);
                this.summary = Object.assign({active: 0, total: 0, performance: 0, hour: 0}, parsedData);
                this.$forceUpdate();
            },
            onEventQSO(event){

                // Handle workers event data
                const parsedData = JSON.parse(event.data);
                this.item = Object.assign(
                    { hour: 0, minute: 0, second: 0, hits: 0, need: 0 },
                    parsedData
                );
                this.hits = this.item.hits;
                this.loadActive = false;
                this.$forceUpdate();
            },
            closeEventStreams() {
                this.eventBandwidth.close();
                this.eventShown.close();
                this.eventweekly.close();
                this.eventWorkers.close();
            },



            isMobile() {
                if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    return true
                } else {
                    return false
                }
            },
            timezoneCheck() {
                if ( this.user && !this.user.timezone ) {
                    this.$bvToast.toast('Select a Timezone in Profile Settings.', {
                        title: `Warning: No Timezone`,
                        variant: 'warning',
                        solid: true
                    })
                }
            },
            load: function(){
                //Load the campaign
                var page
                if(event && event.type == "click" && event.currentTarget && event.currentTarget.className.indexOf(this.bootstrapPaginationClasses.button) > -1){
                    //Pagination triggered event
                    page = (this.page)? ((this.page == 1)? 0 : Number(this.page-1) ) : 0
                } else
                    page = 0;

                var request = {'apikey': this.$root.apikey};
                request.page = page
                request.max = 10;

                this.loadData = true;

                return axios.get(`${this.$root.serverUrl}/admin/shownrate`, {params:request}).then(function(resp){
                    this.shown = (resp.data && resp.data.data)? resp.data : null;
                    this.loadData = false;
                }.bind(this)).catch((e)=>{
                    console.log(e)
                });
            },
            loadVendors: function(){
                //Load the campaign
                var page
                if(event && event.type == "click" && event.currentTarget && event.currentTarget.className.indexOf(this.bootstrapPaginationClasses.button) > -1){
                    //Pagination triggered event
                    console.log(`Pagination event: ${this.page}: ${event.currentTarget.className}`)
                    page = (this.page)? ((this.page == 1)? 0 : Number(this.page-1) ) : 0
                } else
                    page = 0;

                var request = {'apikey': this.$root.apikey};
                request.page = page
                request.max = 5

                this.loadData2 = true;

                return axios.get(`${this.$root.serverUrl}/admin/shownrate/vendor`, {params:request}).then(function(resp){

                    if(resp.data && resp.data.data){

                        this.vendor = resp.data.data[0]
                        this.loadData2 = false;
                    } else{
                        this.vendor = null;
                        this.loadData2 = false;
                    }

                }.bind(this));
            },
            load_campData: function (x,y){
                this.campStat = {};
                this.camp = {};
                this.camp = x;
                this.campStat = y;
            },
            hourlyReport: async function(){
                const request = {'apikey': this.$root.apikey};
                request.timestamp = Date.now();
                try{
                    let resp = await  axios.get(`${this.$root.serverUrl}/admin/shownrate/hourlysummary`, {params:request})
                    if(resp.data && resp.data.length > 0){
                        this.hourlyReportData = [];
                        resp.data[0].Timeframe = "Current Hour";
                        resp.data[1].Timeframe = "Previous Hour";
                        resp.data[2].Timeframe = "Past Hour";
                        this.hourlyReportData.push(resp.data[0]);
                        this.hourlyReportData.push(resp.data[1]);
                        this.hourlyReportData.push(resp.data[2]);
                       // this.hourlyReportModal = true;
                    }
                }catch(e){
                    console.log(e)
                }
            },
            loadWeekly: function(){
                // this.$root.preloader = true;

                var request = {'apikey': this.$root.apikey}


                return axios.get(`${this.$root.serverUrl}/static/dashboard/weekly-shown`, {params:request}).then(function(resp){
                    this.weekly = (resp && resp.data)? resp.data.data : {};
                    this.$root.preloader = false;
                }.bind(this)).catch(function (err) {
                    this.$root.preloader = false;
                    swal("Oops!",err.message, "error");
                }.bind(this));
            },
            loadStats: function(){
                // this.$root.preloader = true;

                var request = {'apikey': this.$root.apikey}

                //request.page = page
                request.page = this.page;
                request.max = this.max;

                return axios.get(`${this.$root.serverUrl}/admin/stats`, {params:request}).then(function(resp){
                    this.stats = (resp && resp.data)? resp.data.data : {};
                    this.$root.preloader = false;
                }.bind(this)).catch(function (err) {
                    this.$root.preloader = false;
                    swal("Oops!",err.message, "error");
                }.bind(this));
            },
            loadCampaignStats: function(){
                //this.$root.preloader = true;

                var request = {'apikey': this.$root.apikey}

                //request.page = page
                request.page = this.page;
                request.max = this.max;
                request.sortName = this.sort.name;
                request.sortDaily = this.sort.daily;
                request.sortMax = this.sort.max;
                request.sortGoodHits = this.sort.goodHits;
                request.sortBadHits = this.sort.badHits;
                request.filterType = this.sort.filterType;
                this.campaignsList = [];

                return axios.get(`${this.$root.serverUrl}/admin/campaign-stats`, {params:request}).then(function(resp){
                    this.campaignsList = (resp && resp.data)? resp.data.data.campaigns.list : {};
                    this.$root.preloader = false;
                }.bind(this)).catch(function (err) {
                    this.$root.preloader = false;
                    swal("Oops!",err.message, "error");
                }.bind(this));
            },
            moniter: function(){
                this.loadStats().finally(function(){
                    if(this.timer)
                        setTimeout(this.moniter, 15000 );
                }.bind(this));
            },
            setFilter: function(filter){
                if(this.searching) return;
                switch (filter){
                    case 'today': { this.filterType = "Today"; break; }
                    case 'yesterday': { this.filterType = "Yesterday"; break; }
                    case '2daysago': { this.filterType = "2 Days Ago"; break; }
                    default: { this.filterType = "Today"; }
                }
            },
            filterECampaigns: function(){
                if(this.ecampaignLoading) return;
                this.ecampaignLoading = true;
                const request = {
                    'apikey': this.$root.apikey,
                };

                switch (this.filterType){
                    case "Today": { request.date = "today"; break; }
                    case "Yesterday": { request.date = "yesterday"; break; }
                    case "2 Days Ago": { request.date = "2daysago"; break; }
                }
                this.missedTotal = 0;
                this.missedClicks = 0;

                axios.get(`${this.$root.serverUrl}/admin/campaigns/e-campaign-filter`,{params:request}).then(function(resp){
                    //Store the stats
                    this.searched = true;
                    let missing = resp.data.missing;
                    if(missing.length === 0){
                        this.ecampaignLoading = false;
                        return;
                    }
                    this.missedTotal = Number(missing.length).toLocaleString();
                    let total = 0;
                    for(let i in missing){
                        total += missing[i].hits;
                    }
                    this.missedClicks = Number(total).toLocaleString();
                    this.ecampaignLoading = false;
                }.bind(this)).catch(() => {
                    // eslint-disable-next-line no-undef
                    swal("Oops!","An error occurred on the server. Try refreshing the page.","error");
                    this.ecampaignLoading = false;
                });
            }
        },
    }
</script>

<style>
    .campaigns {}
    .campaigns .campaign {}
    .campaigns .campaign .name {
        font-size: smaller;
        font-family: "Droid Sans Mono Slashed";
        text-transform: capitalize;
    }
    .campaigns .campaign .hits {}
    .campaigns .campaign .daily-max {}
    #shownrate table.charts-css.column {
        height: 400px;
        max-width: 100%;
        margin: 0 auto;
        --labels-size: 3rem;

        --color-1: rgba(43, 230, 30, 1.0);
        --color-2: rgba(230, 30, 30, 1.0);
        --color-3: rgba(0, 123, 255, 1.0);
    }
    #shownrate table.charts-css.column td {
        border-radius: 8px;
    }
    #shownrate table.charts-css.column td .data {
        font-size: medium;
        font-weight: bold;
        padding: 10px 5px;
        color: #333333;
    }

    #shownrate table.charts-css.bar {
        --color-1: rgba(43, 230, 30, 1.0);
        --color-2: rgba(230, 30, 30, 1.0);
        --color-3: rgba(0, 123, 255, 1.0);
    }
    #shownrate table.charts-css.bar td {
        border-radius: 0px;
    }
    #shownrate table.charts-css.bar td .data {
        font-size: medium;
        font-weight: bold;
        padding: 5px 2px;
        color: #333333;
    }

    .shownsummary.list-group {}
    .shownsummary.list-group .list-group-item {
        margin-bottom: 4px;
    }
    .shownsummary.list-group .list-group-item .badge {
        padding: 0.5em 0.8em;
    }
    .shownsummary.list-group .list-group-item .time {
        font-size: 11px;
    }

    .shownvendors.list-group {}
    .shownvendors.list-group .list-group-item {
        margin-bottom: 4px;
    }
    .shownvendors.list-group .list-group-item .badge {
        padding: 0.5em 0.8em;
    }
    .shownvendors.list-group .list-group-item .time {
        font-size: 11px;
    }
</style>
