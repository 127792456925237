<template>
    <div class="container mr-1">
        <div class="row ">
            <div v-if="current" class="col-12 col-lg-4 mb-2">
                <b-form-select :options="years" v-model="filter.year" />
            </div>
            <div v-if="current" class="col-12 col-lg-4 mb-2">
                <b-form-select :options="months" v-model="filter.month" />
            </div>
            <div v-if="current" class="col-6 col-lg-2 pl-3 pr-0">
                <button type="button" class="btn text-white btn-dark" @click="search"><i class="fa fa-search" title="Search"></i> Search</button>
            </div>
            <div class="col-6 col-lg-2 p-0 ">
                <button type="button" class="btn btn-success" ><i class="fa fa-download" title="Export"></i> Export</button>
            </div>
        </div>
    </div>
<!--    <div class="col-12 col-md-12 col-sm-6 pb-0 ">-->
<!--        <div class="row ">-->
<!--            <div class="form-group mr-1 col-lg-3 col-md-3 col-sm-6 ">-->

<!--                <input type="date" v-model="filter.from" class="form-control date" placeholder="From" v-b-tooltip.hover title="Filter from date"/>-->
<!--            </div>-->
<!--            <div class="form-group mr-1 col-lg-3 col-md-3 col-sm-6 ">-->
<!--                <input type="date" v-model="filter.to" class="form-control date" placeholder="To" v-b-tooltip.hover title="Filter to date"/>-->
<!--            </div>-->


<!--            <button-->
<!--                type="button"-->
<!--                class="btn text-white btn-dark mr-1 ml-3  form-group col-lg-2 col-md-2 col-sm-6 p-0 "-->
<!--                @click="search"><i class="fa fa-search" title="Search"> </i> Search</button>-->
<!--            <button-->
<!--                type="button"-->
<!--                class="btn btn-success mr-1 ml-3   form-group col-lg-2 col-md-2 col-sm-6 p-0  "-->
<!--            >-->
<!--                <i-->
<!--                    class="fa fa-download"-->
<!--                    title="Export"-->
<!--                >-->
<!--                </i>-->
<!--                Export-->
<!--            </button>-->


<!--        </div>-->
<!--        <label class="text-muted">Filter starting date to ending date</label>-->
<!--    </div>-->
</template>

<script>


export default {
    name: "distribution.vue",
    props:['filter','search','export', 'current'],
    data() {
        return {
            months: [
                { value: '0-2', text: 'Jan - Mar' },
                { value: '3-5', text: 'Apr - June' },
                { value: '6-8', text: 'Jul - Sept'},
                { value: '9-11', text: 'Oct - Dec' },
            ],
            years: [
                { value: 2020, text: 2020 },
                { value: 2021, text: 2021 },
                { value: new Date().getFullYear(), text: new Date().getFullYear() },
            ],
        };
    },
}
</script>

<style scoped>

</style>
