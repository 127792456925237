<template>
    <div>
        <div class="">
            <div class="container-fluid" >
                <div class="row text-dark">
                    <div class="col-lg-2 col-md-3 col-sm-6">
                        <div class="tabs-wrap" style="padding-top: 30px; padding-bottom: 10px">
                            <h5>From</h5>
                            <div class="wrap">
                                <ejs-datepicker cssClass="e-outline" class="" id='startPicker' placeholder="start" v-model="filters.start" ></ejs-datepicker>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-3 col-sm-6">
                        <div class="tabs-wrap" style="padding-top: 30px; padding-bottom: 10px">
                            <h5>To</h5>
                            <div class="wrap">
                                <ejs-datepicker cssClass="e-outline" id='startPicker' placeholder="start" v-model="filters.end" ></ejs-datepicker>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-3 col-sm-6">
                        <div class="tabs-wrap" style="padding-top: 30px; padding-bottom: 10px">
                            <h5>Product</h5>
                            <div class="wrap">
                        <select v-model="filters.product" class="form-control ">
                            <option value="" selected>-- Any Product--</option>
                            <option v-for="product in products" :key="product.identifier" :value="product.identifier">{{product.name}}</option>
                        </select>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-12">
                        <div class="tabs-wrap" style="padding-top: 30px; padding-bottom: 10px">
                            <h5>Columns To Be Shown</h5>
                            <span class="e-control-wrapper e-outline e-valid-input e-datetime-wrapper mt-4" >
                    <ejs-multiselect
                        :dataSource="columnOptions"
                        :fields="dfields"
                        v-model="selectedColumns"
                        @change="updateColumnVisibility"
                        placeholder="Select columns to display"
                        cssClass="e-outline"
                    ></ejs-multiselect>
                    </span>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-3 col-sm-6">
                        <div class="tabs-wrap" style="padding-top: 30px; padding-bottom: 10px">
                            <h5>Page Size</h5>
                            <div class="wrap">
                                <ejs-dropdownlist cssClass="e-outline" id='sample-list3' :dataSource='pageSizes' :mode='true' v-model="pageSettings.pageSize" placeholder='Page Size' ></ejs-dropdownlist>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 mb-3">
                        <div class="tabs-wrap justify-content-center text-center" style="padding-top: 10px; padding-bottom: 15px">
                            <button class="e-control e-progress-btn e-lib e-btn e-spin-right" data-ripple="true" aria-label="SEARCH progress" style="color: white; background-color: rgb(56, 56, 56);" @click="getDetails(true)">
                                <span class="e-btn-content">SEARCH</span>
                            </button>

                        </div>
                    </div>
                </div>

            </div>
        </div>

        <hr class="p-1">

        <ejs-grid ref="grid" :dataSource="data" :toolbar='toolbar'
                  :toolbarClick='toolbarClick' :allowFiltering="true"
                  :allowExcelExport="true" :allowPdfExport="true"
                  :allowCsvExport="true" :allowSorting="true"
                  :allowGrouping="true"
                  :pageSettings="pageSettings"
                  :groupSettings='groupSettings'
                  :filterSettings="filterSettings"
                  :excelExportComplete='exportComplete'
                  :pdfExportComplete='exportComplete'
                  :csvExportComplete='exportComplete'
                 >

            <e-columns>

                <e-column field="date"  headerText="Date" text-Align="Left" width="120" :visible="visibleColumns.includes('date')" ></e-column>
                <e-column field="product"  headerText="Product" text-Align="Left" width="120" :visible="visibleColumns.includes('product')" ></e-column>
                <e-column field="day" headerText="Day" text-Align="Left" width="120" :visible="visibleColumns.includes('day')" ></e-column>
                <e-column field="totalClicks" headerText="Total Clicks" text-Align="Left" width="120"  format="N" :visible="visibleColumns.includes('totalClicks')" ></e-column>
                <e-column field="nationwideClicks" headerText="Nationwide Clicks" text-Align="Left" format="N" width="190" :visible="visibleColumns.includes('nationwideClicks')" ></e-column>
                <e-column field="targetedClicks" headerText="Targeted Clicks" text-Align="Left" format="N"  width="190" :visible="visibleColumns.includes('targetedClicks')" ></e-column>
                <e-column field="dataUsedNationwide" headerText="Data Used Nationwide (GB)" text-Align="Left"   width="210" :visible="visibleColumns.includes('dataUsedNationwide')" ></e-column>
                <e-column field="dataUsedTargeted" headerText="Data Used Targeted (GB)" text-Align="Left"   width="190"  :visible="visibleColumns.includes('dataUsedTargeted')" ></e-column>
                <e-column field="costNationwide" headerText="Cost Nationwide" text-Align="Left" format="C"  width="190" :visible="visibleColumns.includes('costNationwide')" ></e-column>
                <e-column field="costTargeted" headerText="Cost Targeted" text-Align="Left" format="C" width="190"  :visible="visibleColumns.includes('costTargeted')" ></e-column>
                <e-column field="perClickCostNationwide" headerText="Per Click Cost Nationwide"  format="C4"  text-Align="Left" width="200"  :visible="visibleColumns.includes('perClickCostNationwide')" ></e-column>
                <e-column field="perClickCostTargeted" headerText="Per Click Cost Targeted"  format="C4"  text-Align="Left" width="190"  :visible="visibleColumns.includes('perClickCostTargeted')" ></e-column>
                <e-column field="perClickCostTotal" headerText="Per Click Cost Total" text-Align="Left"  format="C4"   class="text-primary" :template='cTemplate' width="190"  :visible="visibleColumns.includes('perClickCostTotal')" ></e-column>


            </e-columns>

            <e-aggregates>
                <e-aggregate>
                    <e-columns>
                        <e-column type="Sum" field="capped" format="N" :footerTemplate='footerSum'></e-column>
                        <e-column type="Sum" field="bounce" format="N" :footerTemplate='footerSum'></e-column>
                    </e-columns>
                </e-aggregate>
            </e-aggregates>

        </ejs-grid>
        <ejs-pager :pageSize='pageSettings.pageSize' :pageCount='pageSettings.pageCount'
                   :totalRecordsCount='pageSettings.totalRecordsCount'
                   @click="onPageClick"
        >
        </ejs-pager>


    </div>
</template>


<script>
import Vue from "vue";
import axios from "axios";
import moment from "moment-timezone";
import { DateTimePickerPlugin } from "@syncfusion/ej2-vue-calendars";
import { DropDownListPlugin, ListBoxPlugin,MultiSelectPlugin } from "@syncfusion/ej2-vue-dropdowns";

import { GridPlugin, Page, PdfExport, ExcelExport, Sort, Filter, Group,Toolbar,Aggregate, PagerPlugin } from '@syncfusion/ej2-vue-grids';
// import { DataManager, WebApiAdaptor, Query } from "@syncfusion/ej2-data";
// import { ClickEventArgs } from "@syncfusion/ej2-vue-navigations";
import swal from "sweetalert";

Vue.use(PagerPlugin);
Vue.use(GridPlugin);
Vue.use(DropDownListPlugin);
Vue.use(DateTimePickerPlugin);
Vue.use(ListBoxPlugin);
Vue.use(MultiSelectPlugin)

export default {
    name: "master",
    props: ["user"],
    data: function () {
        return {
            columns: [
                {field: "date", header: "Date", visible: true, textAlign: "Left", format: " ", width: " "},
                {field: "product", header: "Product", visible: true, textAlign: "Left", format: " ", width: " "},
                {field: "day", header: "Day", visible: true, textAlign: "Left", format: " ", width: ""},
                {
                    field: "totalClicks",
                    header: "Total Clicks",
                    visible: true,
                    textAlign: "Left",
                    format: "N",
                    width: ""
                },
                {
                    field: "nationwideClicks",
                    header: "Nationwide Clicks",
                    visible: true,
                    textAlign: "Left",
                    format: "N",
                    width: ""
                },
                {
                    field: "targetedClicks",
                    header: "Targeted Clicks",
                    visible: true,
                    textAlign: "Left",
                    format: "N",
                    width: ""
                },
                {
                    field: "dataUsedNationwide",
                    header: "Data Used Nationwide",
                    visible: true,
                    textAlign: "Left",
                    format: "N",
                    width: ""
                },
                {
                    field: "dataUsedTargeted",
                    header: "Data Used Targeted",
                    visible: true,
                    textAlign: "Left",
                    format: "N",
                    width: ""
                },
                {
                    field: "costNationwide",
                    header: "Cost Nationwide",
                    visible: true,
                    textAlign: "Left",
                    format: "N",
                    width: ""
                },
                {
                    field: "perClickCostNationwide",
                    header: "Per Click Cost Nationwide",
                    visible: true,
                    textAlign: "Left",
                    format: "N",
                    width: ""
                },
                {
                    field: "perClickCostTargeted",
                    header: "Per Click Cost Targeted",
                    visible: true,
                    textAlign: "Left",
                    format: "N",
                    width: ""
                },
                {
                    field: "perClickCostTotal",
                    header: "Per Click Cost Total",
                    visible: true,
                    textAlign: "Left",
                    format: "N",
                    width: ""
                },
                // Add more columns as needed
            ],
            columnOptions: [
                {id: "date", name: "Date"},
                {id: "day", name: "Day"},
                {id: "totalClicks", name: "Total Clicks"},
                {id: "nationwideClicks", name: "Nationwide Clicks"},
                {id: "targetedClicks", name: "Targeted Clicks"},
                {id: "dataUsedNationwide", name: "Data Used Nationwide"},
                {id: "dataUsedTargeted", name: "Data Used Targeted"},
                {id: "costNationwide", name: "Cost Nationwide"},
                {id:'costTargeted', name:'Cost Targeted'},
                {id: "perClickCostNationwide", name: "Per Click Cost Nationwide"},
                {id: "perClickCostTargeted", name: "Per Click Cost Targeted"},
                {id: "perClickCostTotal", name: "Per Click Cost Total"},
                {id:"product",name:'Product'}
                // Add more options for other columns
            ],
            selectedColumns: ["date","product", "day", "totalClicks", "nationwideClicks", "targetedClicks",
                "dataUsedNationwide", "dataUsedTargeted", "costNationwide", "perClickCostNationwide",
                "perClickCostTargeted", "perClickCostTotal","costTargeted"], // Set the initial selected columns
            visibleColumns: ["date","product", "day", "totalClicks", "nationwideClicks", "targetedClicks",
                "dataUsedNationwide", "dataUsedTargeted", "costNationwide", "perClickCostNationwide",
                "perClickCostTargeted", "perClickCostTotal","costTargeted"],
            selectedPageSize: 50,
            groupSettings: {
                showDropArea: true,
                disablePageWiseAggregates: true,
            },
            filterSettings: {
                immediateModeDelay: 500,
                mode: 'Immediate'
            },
            footerSum: function () {
                return {
                    template: Vue.component('sumTemplate', {
                        template: `<span>Total: {{ data.Sum }}</span>`,
                        data() {
                            return {data: {}};
                        }
                    })
                }
            },
            cTemplate: function () {
                return { template : Vue.component('columnTemplate',{
                        template: `<div :class="getColorForProduct(data.product,Number(data.perClickCostTotal))"> {{formatCurrency(data.perClickCostTotal)}}</div>`,
                        data: function() {
                            return {
                                data: {},
                                productColors : {
                                    'domain': {
                                        success: [0.000, 0.006],
                                        warning: [0.0061, 0.009],
                                        danger: [0.01],
                                    },
                                    'email': {
                                        success: [0.000, 0.006],
                                        warning: [0.0061, 0.009],
                                        danger: [0.01],
                                    },
                                    'Push Giant': {
                                        success: [0.00, 0.02],
                                        warning: [0.021, 0.05],
                                        danger: [0.051],
                                    },
                                }
                            }
                        },
                        methods: {
                            formatCurrency(value,decimalPlaces = 4) {
                                return new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                    minimumFractionDigits: decimalPlaces,
                                    maximumFractionDigits: decimalPlaces,
                                }).format(value);
                            },
                            getColorForProduct(productName, value) {
                                const product = this.productColors[productName];

                                if (product) {
                                    if (value >= product.success[0] && value <= product.success[1]) {
                                        return 'text-success';
                                    } else if (value >= product.warning[0] && value <= product.warning[1]) {
                                        return 'text-warning';
                                    } else if (value >= product.danger[0]) {
                                        return 'text-danger';
                                    }
                                }

                                // Default to 'Unknown' if the product name doesn't match any criteria
                                return 'text-danger';
                            },
                        }
                    })}
            },
            pageSizes: [25, 50, 75],
            filters: {
                view: "City",
                start: moment(this.setStartDateToBeginningOfMonth()).startOf("day").toDate(),
                end: moment().endOf("day").toDate(),
                domain: "",
                product:''
            },
            data: [],
            products:[],
            toolbar: ['CsvExport', 'ExcelExport', 'PdfExport'],
            pageSettings: {pageSize: 50},
            preloader: false,
            spinSettings: {duration: 10000000, position: 'Right', width: 20, template: '<div class="template"></div>'},

        }

    },
    computed: {
        dfields() {
            return { text: "name", value: "id" };
        },
    },
    created: function () {
        this.$root.preloader = false;
        this.loadProducts()

    },
    beforeDestroy() {},

    methods: {
        setStartDateToBeginningOfMonth() {
        // Create a new Date object for the current date
        const currentDate = new Date();

        // Set the date to the first day of the current month
        currentDate.setDate(1);

        // Optionally, set the time to midnight (00:00:00)
        currentDate.setHours(0, 0, 0, 0);

        return currentDate;
        },
        productsName(name){
            switch (name) {
                case 'email':
                    return 'Email Verifier'
                case 'domain':
                    return 'AdVerifier'
                case 'pushGiant':
                    return 'Push Giant'
                default:
                    return name
            }
        },
        loadProducts: function(){
            //Load the clients
            var request = {'apikey': this.$root.apikey};
            //this.$root.preloader = true;

            return axios.get(`${this.$root.serverUrl}/admin/products/dropdown-list`, {params:request}).then(function(resp){
                //Store the stats
                this.products = (resp.data && resp.data.data)? resp.data.data : [];

                if(this.products.length != 0) this.products = this.products.filter((item) => item.identifier != 'catcher')
            }.bind(this));
            // return this.products
        },

        exportToExcel() {
            // Get the grid instance using the ref attribute
            const gridInstance = this.$refs.grid.ej2Instances;
            // Trigger the Excel export
            gridInstance.excelExport(this.getExcelExportProperties());
        },
        onPageClick(args) {
            // Update the current page when the user clicks on the pager
            this.filters.page = args.currentPage;
            this.pageSettings.currentPage = args.currentPage;
            this.getDetails(false);
        },

        actionBegin: function(args) {
            if (args.requestType === 'paging') {
                this.filters.page = args.currentPage;
                this.pageSettings.currentPage = args.currentPage;
                this.getDetails(false);
            }
        },
        exportComplete(args) {

            if (args.requestType === 'excelExport' || args.requestType === 'pdfExport') {
                if (args.status === 'success') {
                    // Handle successful export
                } else {
                    console.log(args.error);
                    // Handle export failure or error
                }
            }

            this.$refs.grid.hideSpinner();
        },

        updateColumnVisibility() {
            this.visibleColumns = this.selectedColumns;
        },
        getDetails:function ( count){
            this.$refs.grid.showSpinner();
            this.report = [];
            this.proxieDataSet = {};


            const request = {
                'apikey': this.$root.apikey,
                filters: {
                    start: new Date(),
                    end: new Date()
                }
            };

            request.filters.start = this.filters.start;
            request.filters.end = this.filters.end;
            request.count = count;
            request.pagesize = this.pageSettings.pageSize
            if(this.filters.product) request.filters.product = this.filters.product
            if (this.filters.page) request.filters.page = this.filters.page;

            axios.get(`${this.$root.serverUrl}/admin/clicks/productclicks`, {params: request}).then(function (resp) {
                //Store the stats
                let count = (resp.data) ? resp.data.Count : 0;
                let data = (resp.data) ? resp.data.Items : [];
                this.total = 0
                this.counterCheck = count
                if (resp.data.error) {
                    this.reportLoading = false;
                    swal({title: 'Oops', text: `${resp.data.error.message}`, icon: 'error'})
                } else {
                    // data.forEach((item)=>{item.date = item.date.split('T')[0]; item.product = this.productsName(item.product)})
                    data.forEach((item) => {
                        item.date = item.date.split('T')[0];
                        item.product = this.productsName(item.product);
                        // item.perClickCostTotal = `$${item.perClickCostTotal}`// Call productsName directly without using this
                    });
                    this.data = data
                    this.pageSettings.totalRecordsCount = count;
                    this.pageSettings.pageCount = Math.ceil(count / this.pageSettings.pageSize);

                }
                this.$forceUpdate();
                this.$refs.grid.hideSpinner();
                this.searching = false;
            }.bind(this)).catch(() => {
                this.reportLoading = false;
            });
            // }
        },
        toolbarClick: function(args) {

            switch (args.item.text) {

                case 'Excel Export':
                    // this.exportToExcel();
                    this.$refs.grid.excelExport(this.getExcelExportProperties());
                    break;

                case 'PDF Export':
                    this.$refs.grid.pdfExport(this.getPdfExportProperties());
                    break;
                case 'CSV Export':
                    // this.$refs.grid.csvExport();
                   this.getCsvExportProperties()
                    // (this.$refs.grid).csvExport();
                    break;
            }
        },

        getExcelExportProperties: function() {
            return {
                fileName: "exported_data.xlsx"
            };
        },
        getCsvExportProperties: function() {
            // Clone the data to avoid modifying the original data
            // const dataToExport = [...this.data];
            //
            // // Add the "$" symbol to the "currencyField" values
            // dataToExport.forEach((item) => {
            //     if (item.perClickCostTotal !== undefined && !isNaN(item.perClickCostTotal)) {
            //         item.perClickCostTotal = '$' + item.perClickCostTotal; // Adjust the format as needed
            //     }
            // });

            const customExportSettings = {
                dataSource: this.data,
                columns: [
                    {field:"date" ,headerText:"Date"},
                    {field:"product" ,headerText:"Product"},
                    {field:"day",headerText:"Day"},
                    {field:"totalClicks",headerText:"Total Clicks", format:"N"},
                    {field:"nationwideClicks",headerText:"Nationwide Clicks", format:"N"},
                    {field:"targetedClicks",headerText:"Targeted Clicks", format:"N"},
                    {field:"dataUsedNationwide",headerText:"Data Used Nationwide (GB)"},
                    {field:"dataUsedTargeted",headerText:"Data Used Targeted (GB)"},
                    {field:"costNationwide",headerText:"Cost Nationwide",format:"C"},
                    {field:"costTargeted",headerText:"Cost Targeted",format:"C"},
                    {field:"perClickCostNationwide",headerText:"Per Click Cost Nationwide", format:"C4"},
                    {field:"perClickCostTargeted",headerText:"Per Click Cost Targeted", format:"C4"},
                    {field:"perClickCostTotal",headerText:"Per Click Cost Total", format:"C4"},
                    // { field: "perClickCostTotal",headerText:"Per Click Cost Total", format: "C4" },
                    // Add other columns with format settings as needed
                ],
            };
            this.$refs.grid.csvExport(customExportSettings);
            // Trigger CSV export with the modified data
            // this.$refs.grid.csvExport({ dataSource: dataToExport ,   fileName: "exported_data.csv"});
        },
        getPdfExportProperties: function() {
            return {
                fileName: "exported_data.pdf"
            };
        },
        toDate(item) {
            return moment(item).format('MM-DD-YYYY')
        }
    },
    provide: {
        grid: [Page, Sort, Filter,Group,Toolbar,PdfExport,ExcelExport,Aggregate]
    },
};
</script>

<style scoped>
@import "../../../../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
@import '../../../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../../../node_modules/@syncfusion/ej2-vue-buttons/styles/material.css';
@import '../../../../../node_modules/@syncfusion/ej2-vue-calendars/styles/material.css';
@import '../../../../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css';
@import '../../../../../node_modules/@syncfusion/ej2-vue-inputs/styles/material.css';
@import '../../../../../node_modules/@syncfusion/ej2-vue-navigations/styles/material.css';
@import '../../../../../node_modules/@syncfusion/ej2-vue-popups/styles/material.css';
@import '../../../../../node_modules/@syncfusion/ej2-vue-schedule/styles/material.css';



.sticky-header {
    position: sticky;
    top: 0;
    background-color: #f0f0f0; /* Set the background color for the sticky headers */
    /* Add any additional custom styles for the sticky headers */
}

.e-datetime-wrapper .e-input-group-icon.e-date-icon, .e-datetime-wrapper .e-input-group-icon.e-time-icon {
    font-size: 16px;
    background-color: #ffffff;
}

/*.wrap{*/
/*    background-color: white;*/
/*}*/
</style>
