
    <template>
        <div id="app4">
            <div class="container">
            <div class="row card b-card ">
                <div class="col-lg-6 col-md-6">
                    <div  class="form-group mr-1">
                        <div class="tabs-wrap">
                            <p class="pt-3">Select the Email Engagement Metrics</p>
                            <div class="wrap">
                                <select
                                    v-model="filters.chart4"
                                    class="form-control text-capitalize required"
                                    required
                                    @change="handleStatusChange($event)"
                                >
<!--                                    <option value="opened" selected>Opened</option>-->
<!--                                    <option value="reply" selected>Reply</option>-->
<!--                                    <option value="forward" selected>Forward</option>-->
<!--                                    <option value="deleted" selected>Delete</option>-->

                                    <option value="y" selected>Opened</option>
                                    <option value="y2" >Reply</option>
                                    <option value="y1" >Forward</option>
                                    <option value="y3" >Delete</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>

            <ejs-chart id="container4" :title='title' :primaryXAxis='primaryXAxis' :tooltip='tooltip' :palettes='palettes' >
                <e-series-collection>
                    <e-series :dataSource='seriesData' type='StackingColumn' xName='x' yName='y' name='Gmail'> </e-series>
                    <e-series :dataSource='seriesData' type='StackingColumn' xName='x' yName='y1' name='Yahoo'> </e-series>
                    <e-series :dataSource='seriesData' type='StackingColumn' xName='x' yName='y2' name='Outlook'> </e-series>
                    <e-series :dataSource='seriesData' type='StackingColumn' xName='x' yName='y3' name='Other'> </e-series>
                </e-series-collection>
            </ejs-chart>
        </div>

</template>
<style scoped>
.chart-content {
    height: 100%;
    width: 100%;
}
#container1, #container2, #container3 {
    height: inherit !important;
    width: inherit !important;
}
#chartcontainer {
    height: 550px;
    width: 100%;
}

@media screen and (max-width: 1024px) {
    #chartcontainer {
        height: 950px; /* Adjust the height for smaller screens */
    }
}
</style>
<script>
import Vue from "vue";
Vue.config.silent = true;
import { Browser } from '@syncfusion/ej2-base';
import { ChartPlugin, StackingColumnSeries, Legend, BarSeries, Category, Tooltip, Highlight,MultiLevelLabel, ColumnSeries, DataLabel,StackingBarSeries,} from "@syncfusion/ej2-vue-charts";
import axios from "axios";
import swal from "sweetalert";
import {hideSpinner, showSpinner} from "@syncfusion/ej2-vue-popups";

// import { createSpinner, showSpinner, hideSpinner } from '@syncfusion/ej2-vue-popups';
Vue.use(ChartPlugin);

let selectedTheme = location.hash.split("/")[1];
selectedTheme = selectedTheme ? selectedTheme : "Material";
let theme = (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)).replace(/-dark/i, "Dark").replace(/contrast/i, 'Contrast');

export default Vue.extend({
    props:['charts','chartId','seriesData','filters','filteredDataTwo'],
    data: function() {
        return {
            xhrInterval: null,
            chartHeight: '100%', // Set the initial chart height
            chartWidth: '100%',
            theme: theme,
            palettes: ["#b3b3b3","#e5c494", "#ffd92f", "#a6d854" ],
            //Initializing Primary X Axis
            primaryXAxis: {
                title: '',
                interval: 1,
                valueType: 'Category',
            },
            marker: {
                dataLabel: {
                    visible: true,
                    name: 'DataLabelMappingName',
                    position: Browser.isDevice ? 'Middle' : 'Middle',
                    font: { fontWeight: '600', color: Browser.isDevice ? '#404041' : '#0c0c0c' }
                }
            },
            marker2: {
                dataLabel: {
                    visible: true,
                    name: 'DataLabel',
                    position: Browser.isDevice ? 'Top' : 'Top',
                    font: { fontWeight: '600', color: Browser.isDevice ? '#404041' : '#0c0c0c' }
                }
            },
            //Initializing Primary Y Axis
            primaryYAxis:
                {
                    rangePadding: 'None',
                    interval: 2000,
                    title: 'Number Of Clicks Per Hour',
                    lineStyle: { width: 0 },
                    majorTickLines: { width: 0 },
                    majorGridLines: { width: 1 },
                    minorTickLines: { width: 0 },
                },
            chartArea: {
                border: {
                    width: 0
                }
            },
            tooltip: {
                enable: true,
                format: '${point.x} : <b >${point.y}</b>'
            },
            border: {color: '#ffffff', width:1},
            legend: {enableHighlight : true, },
            width : Browser.isDevice ? '100%' : '100%',
            type: Browser.isDevice ? 'StackingBar' : 'StackingColumn',
            type2: Browser.isDevice ? 'Bar' : 'Column',
            title: "Inbox Enhanced"
        };
    },
    mounted() {


    },
    created:function(){

    },
    provide: {
        chart: [StackingColumnSeries, Category, Tooltip, Legend, Highlight,MultiLevelLabel,ColumnSeries, DataLabel,StackingBarSeries,BarSeries]
    },
    beforeDestroy() {

    },
    methods: {
        async handleStatusChange() {
            showSpinner(document.getElementById('container4'));
            const request = {'apikey': this.$root.apikey, sSearch:null, filters:{}};
            if(this.filters.client) request.filters.client = this.filters.client;
            if(this.filters.campaign) request.filters.campaign = this.filters.campaign;
            if(this.filters.end) request.filters.end = this.filters.end;
            if(this.filters.start) request.filters.start = this.filters.start;
            if(this.filters.timePeriod) request.filters.timePeriod = this.filters.timePeriod;
            request.user = this.$store.state.user
            request.filters.chart2 = this.filters.chart2;
            request.filters.chart4 = this.filters.chart4;
            request.filters.singleRequest = true
            request.filters.singleRequestchart2 = false

            const mainAverageChart = await axios.get(`${this.$root.serverUrl}/static/dashboard/overviewChartAverage`,{params:request});


            if(mainAverageChart.data.error){
                // this.reportLoading = false;
                hideSpinner(document.getElementById('container4'));
                swal({title:'Oops', text: `${mainAverageChart.data.error.message}`, icon: 'error'})}

            else {
                this.subAverageChart = mainAverageChart.data.Items.subAverageChart
                this.seriesData = mainAverageChart.data.Items.subAverageChart;
                hideSpinner(document.getElementById('container4'));

            }
            // let selectedValue = event.target.value;
            // this.seriesData = this.filteredData.map(item => {
            //     return {
            //         x: item.x,
            //         [selectedValue]: item[selectedValue]  // Dynamically access the selected key
            //     };
            // });
            this.$forceUpdate()
        },
    },

});
</script>

    <style>
    .b-card {
        height: 120px;
    }
    </style>
