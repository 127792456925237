<template>
    <div id="app">

        <ejs-grid :dataSource="getData" :allowResizing='true' height='430' :gridLines='lines' >
            <e-columns>

                <e-column field='hour' headerText='Hours' textAlign='left' width=75 height=500 class="e-headercell"></e-column>
                <e-column field='actual_total' headerText='Impression' width=100></e-column>
                <e-column field='actual_shown' headerText='Shown Impression' width=110></e-column>
                <e-column field='actual_shown_rate' headerText='% Actual Shown Impression' width=110></e-column>
                <e-column field='estimated_shown' headerText='Estimated Hourly Impression' width=180></e-column>
                <e-column field='estimated_shown_rate' headerText='% Estimated Shown Impression' width=180></e-column>
            </e-columns>
        </ejs-grid>
    </div>
</template>

<script>
import Vue from "vue";
import { GridPlugin, Resize } from "@syncfusion/ej2-vue-grids";

Vue.use(GridPlugin);
export default {
    name: "actuals.vue",
    props:['items'],
    data() {
        return {
            lines: 'Both'
        };
    },
    provide: {
        grid: [Resize]
    },
    computed: {
        getData() {
            var data = this.items?this.items:[];
            data = this.$store.state ? this.$store.state.actuals : data;
            return data;
        },
    },
}
</script>

<style scoped>
@import "../../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
</style>
