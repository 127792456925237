<template>
    <div>
        <div class="card mb-5">
            <div class="card-header font-wight-bolder text-white" style="background-color: #0938b3">
                <div class="row">
                    <div class="col-9">
                        <h4 class="pt-2">Current Data (EST)</h4>
                    </div>
                    <div class="col-3 align-items-end">
                        <b-form-select v-model="timezone" :options="timezones" @input="forecastEventStream" />
                    </div>
                </div>
            </div>
            <div class="row col-12 ml-1 mt-1 mb-2">
                <div class="col-12 col-md-4 col-sm-4">
                    <div class="row p-2">
                        <b-card header-tag="header">
                            <template #header>
                                <h6 class="mb-0 font-weight-bolder">Forecasted Information</h6>
                            </template>
                            <forcasted class="p-2" />
                        </b-card>
                    </div>
                </div>
                <div class="col-12 col-md-8 col-sm-8">
                    <div class="row p-2">
                        <b-card header-tag="header">
                            <template #header>
                                <h6 class="mb-0 font-weight-bolder">Actuals</h6>
                            </template>
                            <actuals :items="data" class="p-2" />
                        </b-card>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mb-5">
            <div
                class="card-header font-wight-bolder text-white"
                style="background-color: #0938b3"
            >
                <h4 class="pt-2">Distribution and Trends</h4>
            </div>
            <div class="row col-12 ml-1 mt-1 mb-2">
                <div class="col-12 col-md-6 col-sm-6 p-2">
                    <b-card header-tag="header">
                        <template #header>
                            <h6 class="mb-0 font-weight-bolder">Current Day vs Past Day</h6>
                        </template>
                        <past-day-filter :filter="pastDay" :search="fireEvent" />
                        <currentDay :filter="pastDay" class="p-2" />
                    </b-card>
                </div>
                <div class="col-12 col-md-6 col-sm-6 p-2">
                    <b-card header-tag="header">
                        <template #header>
                            <h6 class="mb-0 font-weight-bolder">Current Month vs Past Month</h6>
                        </template>
                        <past-month-filter :filter="pastMonth" :search="fireMonthEvent" />
                        <currentMonth :filter="pastMonth" class="p-2" />
                    </b-card>
                </div>
            </div>

            <div class="row col-12 ml-1 mt-1 mb-2">
                <div class="col-12 col-md-6 col-sm-6">
                    <div class="row p-2">
                        <b-card header-tag="header">
                            <template #header>
                                <h6 class="mb-0 font-weight-bolder">
                                    Current Day of the Week Distribution
                                </h6>
                            </template>
                            <distributionFilter :filter="filter" :current="true" :search="getEvents2" />
                            <currentDayTable class="p-2" />
                        </b-card>
                    </div>
                </div>
                <div class="col-12 col-md-6 col-sm-6">
                    <div class="row p-2">
                        <b-card header-tag="header">
                            <template #header>
                                <h6 class="mb-0 font-weight-bolder">Past Day of the Week Distribution</h6>
                            </template>
                            <distributionFilter :filter="filter" :search="getEvents" />
                            <currentDayTable filter="past" class="p-2" />
                        </b-card>
                    </div>
                </div>
            </div>
        </div>
        <!--        </div>-->
        <!--        <div class="row col-12 mb-5">-->

        <!--            <div class="col-12" style="background-color:blue; padding:0%;">-->
        <!--                <h1>Distribution and Trends</h1>-->
        <!--            </div>-->
        <!--            <div class="col-6" >-->
        <!--                <div class="row">-->
        <!--                    Current Day vs Past Day -->
        <!--                </div>-->
        <!--            </div>-->
        <!--            <div class="col-6" >-->
        <!--                <div class="row">       -->
        <!--                    Current Month vs Past Month           -->
        <!--                </div>-->
        <!--            </div>-->
        <!--            <div class="col-6" >-->
        <!--                <div class="row">-->
        <!--                    Current Day of the Week Distribution -->
        <!--                </div>-->
        <!--            </div>-->
        <!--            <div class="col-6" >-->
        <!--                <div class="row">       -->
        <!--                    Past Day of the Week Distribution            -->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->
    </div>
</template>

<script>
import axios from "axios";
import forcasted from "@/components/dashboard/monitoring/tables/forcasted";
import actuals from "@/components/dashboard/monitoring/tables/actuals";
import currentDay from "@/components/dashboard/monitoring/charts/currentDay";
import currentMonth from "@/components/dashboard/monitoring/charts/currentMonth";
import currentDayTable from "@/components/dashboard/monitoring/tables/currentDay";
import distributionFilter from "@/components/dashboard/monitoring/filters/distribution";
import pastDayFilter from "@/components/dashboard/monitoring/filters/pastDay";
import pastMonthFilter from "@/components/dashboard/monitoring/filters/pastMonth";

import { Resize } from "@syncfusion/ej2-vue-grids";
import moment from "moment-timezone";

var range = {0: "0-2", 1: "0-2", 2: "0-2", 3: "3-5", 4: "3-5", 5: "3-5", 6: "6-8", 7: "6-8", 8: "6-8", 9: "9-11", 10: "9-11", 11: "9-11"};

export default {
    name: "Dashboard",
    props: ["user"],
    components: {
        forcasted,
        actuals,
        currentDay,
        currentMonth,
        currentDayTable,
        distributionFilter,
        pastDayFilter,
        pastMonthFilter,
    },
    data() {
        return {
            data: [],
            timezone: '',
            timezones: [],
            pastDay: { year: new Date().getFullYear(), month: new Date().getMonth(), day: new Date().getDate(), view: "shown_rate" },
            pastMonth: { year: new Date().getFullYear(), month: new Date().getMonth(), view: "shown_rate" },
            filter: { year: new Date().getFullYear(), month: range[new Date().getMonth()] },
            tableTwoFilter: { year: new Date().getFullYear(), month: range[new Date().getMonth()] },
            eventForecast: null,
            eventActual: null,
            eventCurrentDay: null,
            eventCurrentMonth: null,
            eventCurrentWeek: null,
        };
    },
    provide: {
        grid: [Resize],
        gridLines: true,
    },
    mounted: function () {
        this.timezones = moment.tz.names()
        this.timezone = this.user.timezone;
        this.timezones.unshift({ value: '', text: 'Select Timezone', disabled: true })
        this.load();
    },
    onBeforeUnmount:() => {
        if (this.eventForecast) {
            this.eventForecast.close();
            this.eventForecast = null;
        }
        if (this.eventActual) {
            this.eventActual.close();
            this.eventActual = null;
        }
        if(this.eventCurrentDay) {
            this.eventCurrentDay.close();
            this.eventCurrentDay = null;
        }
        if(this.eventCurrentWeek) {
            this.eventCurrentWeek.close();
            this.eventCurrentWeek = null;
        }
        if(this.eventCurrentMonth) {
            this.eventCurrentMonth.close();
            this.eventCurrentMonth = null;
        }
    },
    beforeDestroy: function () {
        if (this.eventForecast) {
            this.eventForecast.close();
            this.eventForecast = null;
        }
        if (this.eventActual) {
            this.eventActual.close();
            this.eventActual = null;
        }
        if(this.eventCurrentDay) {
            this.eventCurrentDay.close();
            this.eventCurrentDay = null;
        }
        if(this.eventCurrentWeek) {
            this.eventCurrentWeek.close();
            this.eventCurrentWeek = null;
        }
        if(this.eventCurrentMonth) {
            this.eventCurrentMonth.close();
            this.eventCurrentMonth = null;
        }
    },
    methods: {
        fireEvent() {
            console.log(this.pastDay);
            console.log("Past Day Filter");

            var request = {
                current: new Date(),
                ...this.pastDay,
                timezone: this.timezone
            };
            request.timestamp = Date.now();
            axios
                .get(`${this.$root.serverUrl}/static/dashboard/trend/current/day`, {
                    params: request,
                })
                .then((resp) => {
                    // console.log('setSeriesDataDay resp data',  resp.data);
                    if (resp.data && resp.data.data && resp.data.data.length > 0) {
                        var data = resp.data;
                        // console.log('setSeriesDataDay resp data', data);
                        this.$store.commit("setSeriesDataDay", data);
                    }
                });
        },
        fireMonthEvent() {
            console.log(this.pastMonth);
            console.log("Past Month Filter");

            var request = {
                current: new Date(),
                ...this.pastMonth,
                timezone: this.timezone
            };
            request.timestamp = Date.now();

            axios
                .get(`${this.$root.serverUrl}/static/dashboard/trend/current/month`, {
                    params: request,
                })
                .then((resp) => {
                    console.log("setSeriesDataMonth resp data", resp.data);
                    if (resp.data && resp.data.data && resp.data.data.length > 0) {
                        var data = resp.data;
                        console.log("setSeriesDataMonth resp data", data);
                        this.$store.commit("setSeriesDataMonth", data);
                    }
                });
        },
        getEvents() {
            console.log(this.tableTwoFilter);
            var request = {
                apikey: this.$root.apikey,
                ...this.tableTwoFilter,
                timezone: this.timezone
            };

            axios
                .get(`${this.$root.serverUrl}/static/dashboard/trend/current/week`, {
                    params: request,
                })
                .then((resp) => {
                        console.log("setCurrentDay resp data1", resp.data);
                        if (resp && resp.data && resp.data.data && resp.data.data.length > 0) {
                            var data = resp.data;
                            console.log("setCurrentDay resp data2", data);
                            this.$store.commit("setCurrentDay", data);
                        }
                    }
                );
            //   alert("Search Event");
        },
        getEvents2() {
            console.log(this.filter);
            var request = {
                apikey: this.$root.apikey,
                ...this.filter,
                timezone: this.timezone
            };

            axios
                .get(`${this.$root.serverUrl}/static/dashboard/trend/current/week`, {
                    params: request,
                })
                .then((resp) => {
                        console.log("setCurrentDay resp data1", resp.data);
                        if (resp && resp.data && resp.data.data && resp.data.data.length > 0) {
                            var data = resp.data;
                            console.log("setCurrentDay resp data2", data);
                            this.$store.commit("setCurrentDay", data);
                        }
                    }
                );
            //   alert("event fired search event");
        },
        load: function () {
            var time = new Date();
            var request = {
                apikey: this.$root.apikey,
                current: time,
                timezone: this.timezone
            };
            request.timestamp = Date.now();
            axios
                .get(`${this.$root.serverUrl}/static/dashboard/current/forecast`, {
                    params: request,
                })
                .then(
                    function (resp) {
                        console.log("setForcated resp data", resp.data);
                        if (resp && resp.data && resp.data.data && resp.data.data.length > 0) {
                            var data = resp.data.data;
                            console.log("setForcated resp data", data);
                            this.$store.commit("setForcated", data);
                            this.forecastEventStream();
                        }
                    }.bind(this)
                );
            axios
                .get(`${this.$root.serverUrl}/static/dashboard/current/actual`, {
                    params: request,
                })
                .then(
                    function (resp) {
                        if (resp && resp.data && resp.data.data && resp.data.data.length > 0) {
                            var data = resp.data.data;
                            // console.log('chart resp data', data);
                            this.$store.commit(
                                "setActuals",
                                data.filter((item) => typeof item.actual_total === "string")
                            );
                            this.actualsEventStream();
                        }
                    }.bind(this)
                );
            axios
                .get(`${this.$root.serverUrl}/static/dashboard/trend/current/day`, {
                    params: request,
                })
                .then(
                    function (resp) {
                        // console.log('setSeriesDataDay resp data',  resp.data);
                        if (resp && resp.data && resp.data.data && resp.data.data.length > 0) {
                            var data = resp.data;
                            // console.log('setSeriesDataDay resp data', data);
                            this.$store.commit("setSeriesDataDay", data);
                        }
                    }.bind(this)
                );
            axios
                .get(`${this.$root.serverUrl}/static/dashboard/trend/current/month`, {
                    params: request,
                })
                .then(
                    function (resp) {
                        console.log("setSeriesDataMonth resp data", resp.data);
                        if (resp && resp.data && resp.data.data && resp.data.data.length > 0) {
                            var data = resp.data;
                            console.log("setSeriesDataMonth resp data", data);
                            this.$store.commit("setSeriesDataMonth", data);
                        }
                    }.bind(this)
                );
            // request.previous = new Date(new Date().setFullYear(new Date().getFullYear() - 1), time.getMonth(),time.getDate())
            request = {
                apikey: this.$root.apikey,
                ...this.tableTwoFilter
            };
            axios
                .get(`${this.$root.serverUrl}/static/dashboard/trend/current/week`, {
                    params: request,
                })
                .then(
                    function (resp) {
                        console.log("setCurrentDay resp data", resp.data);
                        if (resp && resp.data && resp.data.data && resp.data.data.length > 0) {
                            var data = resp.data;
                            console.log("setCurrentDay resp data", data);
                            this.$store.commit("setCurrentDay", data);
                        }
                    }.bind(this)
                );
        },

        forecastEventStream() {
            //Register eventstream
            if (this.eventForecast) {
                this.eventForecast.close();
                this.eventForecast = null;
            }

            if (!this.eventForecast) {
                var time = new Date();
                var request = new URLSearchParams({
                    apikey: this.$root.apikey,
                    current: time,
                    timezone: this.timezone
                });
                this.eventForecast = new EventSource(
                    `${this.$root.serverUrl}/static/dashboard/current/forecast/stream?${request}`,
                    {
                        withCredentials: false,
                    }
                );
                this.eventForecast.onerror = (error) => {
                    console.log(`eventForecast.error`, error);
                };
                this.eventForecast.onopen = (args) => {
                    console.log(`eventForecast.open`, args);
                };
                this.eventForecast.onmessage = (event) => {
                    try {
                        var parsedData = JSON.parse(event.data);

                        // console.log(`eventForecast.onmessage: `, parsedData);
                        this.$store.commit("setForcated", parsedData);
                        this.$forceUpdate();
                    } catch (e) {
                        console.log(`eventForecast.catch: ${e.message}`);
                    }
                };
            }
        },
        actualsEventStream() {
            //Register eventstream
            if (this.eventForecast) {
                this.eventForecast.close();
                this.eventForecast = null;
            }

            if (!this.eventActual) {
                var time = new Date();
                var request = new URLSearchParams({
                    apikey: this.$root.apikey,
                    current: time,
                    timezone: this.timezone
                });
                this.eventActual = new EventSource(
                    `${this.$root.serverUrl}/static/dashboard/current/actual/stream?${request}`,
                    {
                        withCredentials: false,
                    }
                );
                this.eventActual.onerror = (error) => {
                    console.log(`eventActual.error`, error);
                };
                this.eventActual.onopen = (args) => {
                    console.log(`eventActual.open`, args);
                };
                this.eventActual.onmessage = (event) => {
                    try {
                        var parsedData = JSON.parse(event.data);

                        // console.log(`eventActual.onmessage: `, parsedData);
                        var data = parsedData.filter((item) => typeof item.actual_total === "string");
                        // console.log(`eventActual.onmessage: `, data);
                        this.$store.commit("setActuals", data);
                        // this.$forceUpdate();
                    } catch (e) {
                        console.log(`eventActual.catch: ${e.message}`);
                    }
                };
            }
        },
    },
};
</script>
