<template>
    <div >
<!--        <div class="row">-->
<!--            <div class="col-md-12 3">-->
              <weeklyReport/>
<!--            </div>-->
<!--        </div>-->
    </div>
</template>

<script>
import weeklyReport from "../../components/reports/weeklyReport.vue";
export default {
    components:{weeklyReport},
    data() {
        return {

        };
    },
    methods: {
        sendEmail() {
            // Implement OAuth2 authentication and email sending logic here
            // You'll need to make an API request to your server for this part.
            console.log('Sending email...');
        },
    },
};
</script>
