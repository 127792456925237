<template>
    <div class="container ml-2">
        <div class="row">
            <div class="col-12 col-lg-4 mb-2">
                <b-form-select :options="options" v-model="filter.view" />
            </div>
            <div class="col-12 col-lg-2 mb-2">
                <b-form-select :options="years" v-model="filter.year" />
            </div>
            <div class="col-12 col-lg-2 mb-2">
                <b-form-select :options="months" v-model="filter.month" />
            </div>
            <div class="col-12 col-lg-4">
                <button type="button" class="btn text-white btn-dark" @click="search"><i class="fa fa-search" title="Search"></i>Search</button>
            </div>
        </div>
    </div>
</template>

<script>


export default {
    name: "pastDay.vue",
    props:['filter','search'],
    data() {
        return {
            options: [
                { text: 'Shown Rates %', value: 'shown_rate' },
                { text: 'Impressions', value: 'shown' }
            ],
            months: [
                { value: '', text: 'Month', disabled: true },
                { value: 0, text: 'Jan' },
                { value: 1, text: 'Feb' },
                { value: 2, text: 'Mar' },
                { value: 3, text: 'Apr' },
                { value: 4, text: 'May' },
                { value: 5, text: 'Jun' },
                { value: 6, text: 'Jul' },
                { value: 7, text: 'Aug' },
                { value: 8, text: 'Sept' },
                { value: 9, text: 'Oct' },
                { value: 10, text: 'Nov' },
                { value: 11, text: 'Dec' },
            ],
            years: [
                { value: 2019, text: 2019 },
                { value: 2020, text: 2020 },
                { value: 2021, text: 2021 },
                { value: new Date().getFullYear(), text: new Date().getFullYear() },
            ],
        };
    },
}
</script>

<style scoped>

</style>
