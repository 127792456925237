<template>
    <div>
        <div class="row mb-3">
            <div class="col-12 col-md-12 mb-3">
                <div class="card">
                    <provider/>

                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="card">
                    <div class="card-header font-wight-bolder text-white" style="background-color:#0938b3;">
                        <div class="row">
                            <div class="col-6">
                                <h5 class="pt-2">Data Usage by Hour by Provider</h5>
                            </div>
                            <div class="col-6 align-items-end">
                                <b-form-select v-model="timezone" :options="timezones" @input="changeTimezone"/>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <b-card header-tag="header">
                                    <dataUsage class="p-2"/>
                                </b-card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="card">
                    <div class="card-header font-wight-bolder text-white" style="background-color:#0938b3;">
                        <div class="row">
                            <div class="col-6">
                                <h5 class="pt-2">Data Usage by Day by Provider</h5>
                            </div>
                            <div class="col-6">
                                <usageByDay />
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="card">
                                    <div class="card-body">
                                        <dayDataUsage />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mt-2">
                    <div class="card-header font-wight-bolder text-white" style="background-color:#0938b3;">
                        <div class="row">
                            <div class="col-6">
                                <h5 class="pt-2">Per Impression Data Usage</h5>
                            </div>
                            <div class="col-6">
                                <perImpressionFilter :time="timeX" :handle="çhanged" />
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="card">
                                    <div class="card-body">
                                        <perImpression/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-md-6">
                <div class="card mt-2">
                    <div class="card-header font-wight-bolder text-white" style="background-color:#0938b3;">
                        <div class="row">
                            <div class="col-6">
                                <h5 class="pt-2">Top Websites Bounced</h5>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="card">
                                    <div class="card-body">
                                        <topBounced />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="card mt-2">
                    <div class="card-header font-wight-bolder text-white" style="background-color:#0938b3;">
                        <div class="row">
                            <div class="col-6">
                                <h5 class="pt-2">Low TOS</h5>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="card">
                                    <div class="card-body">
                                        <lowTOS />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3 mb-3">
            <div class="col-12">
                <div class="card mt-2">
                    <div class="card-header font-wight-bolder text-white" style="background-color:#0938b3;">
                        <div class="row">
                            <div class="col-6">
                                <h5 class="pt-2">User Agents</h5>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="card">
                                    <div class="card-body">
                                        <userAgent />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import moment from "moment-timezone";
import dataUsage from "@/components/dashboard/proxy/charts/data-usage";
import dayDataUsage from "@/components/dashboard/proxy/charts/data-usage-day";
import perImpression from "@/components/dashboard/proxy/charts/per-impression";
import topBounced from "@/components/dashboard/proxy/tables/topBounced";
import provider from "@/components/dashboard/proxy/tables/providers";
import lowTOS from "@/components/dashboard/proxy/tables/lowTOS";
import userAgent from "@/components/dashboard/proxy/tables/userAgents";
import usageByDay from "@/components/dashboard/proxy/filters/usageByDay";
import perImpressionFilter from "@/components/dashboard/proxy/filters/perImpression";
// import {showSpinner} from "@syncfusion/ej2-vue-popups";
import Vue from "vue";
Vue.prototype.$eventHub = new Vue();
export default {
    name: 'Dashboard',
    props:['user'],
    components: {
        dataUsage,
        dayDataUsage,
        perImpression,
        topBounced,
        lowTOS,
        userAgent,
        usageByDay,
        perImpressionFilter,
        provider
    },
    data() {
        return {
            timezone: "America/New_York",
            timezones: [],
            timeX: new Date(),
            eventDataUsage: null,
            eventDataUsageDays: null,
            eventDataUsageImpressions: null,
            eventBouncedWebsites: null,
            eventTos: null,
            eventUserAgent: null,
        }
    },
    created() {
        this.timezones = moment.tz.names();
        this.timezone = this.user.timezone || "America/New_York";
        this.timezones.unshift({ value: '', text: 'Select Timezone', disabled: true })
    },
    provide: { },
    watch: {
        timeX(newVal, oldVal) {
            console.log('Prop changed: ', newVal, ' | was: ', oldVal)
        }
        // time: {
        //     // the callback will be called immediately after the start of the observation
        //     immediate: true, 
        //     handler (newVal, oldVal) {
        //     // do your stuff
        //     console.log('Prop changed: ', newVal, ' | was: ', oldVal)
        //     }
        // }
    },
    mounted() {
        this.load();
        // this.datausageEventStream();
        // this.datausageDayEventStream();
        // this.datausageImpressionsEventStream();
        // this.datausageBouncedWebsitesEventStream();
        // this.datausageTosEventStream();
        // this.datausageUseragentEventStream();
    },

    onBeforeUnmount:() => {
        if (this.eventDataUsage) {
        this.eventDataUsage.close();
        this.eventDataUsage = null;
        }
        if (this.eventDataUsageDays) {
        this.eventDataUsageDays.close();
        this.eventDataUsageDays = null;
        }
        if (this.eventDataUsageImpressions) {
        this.eventDataUsageImpressions.close();
        this.eventDataUsageImpressions = null;
        }
        if (this.eventBouncedWebsites) {
        this.eventBouncedWebsites.close();
        this.eventBouncedWebsites = null;
        }
        if (this.eventTos) {
        this.eventTos.close();
        this.eventTos = null;
        }
        if (this.eventUserAgent) {
        this.eventUserAgent.close();
        this.eventUserAgent = null;
        }
    },
    beforeDestroy: function () {
        if (this.eventDataUsage) {
        this.eventDataUsage.close();
        this.eventDataUsage = null;
        }
        if (this.eventDataUsageDays) {
        this.eventDataUsageDays.close();
        this.eventDataUsageDays = null;
        }
        if (this.eventDataUsageImpressions) {
        this.eventDataUsageImpressions.close();
        this.eventDataUsageImpressions = null;
        }
        if (this.eventBouncedWebsites) {
        this.eventBouncedWebsites.close();
        this.eventBouncedWebsites = null;
        }
        if (this.eventTos) {
        this.eventTos.close();
        this.eventTos = null;
        }
        if (this.eventUserAgent) {
        this.eventUserAgent.close();
        this.eventUserAgent = null;
        }
    },
    methods: {
        çhanged(data){
            // this.$emit('update:myProp: ',this.time)
            this.timeX = data;
            // console.log('Prop changed: ', this.time, data)
            this.$eventHub.$emit('showSpinnerImpression');
            this.datausageImpressionsEventStream();
        },
        changeTimezone() {
            this.datausageImpressionsEventStream();
            this.datausageDayEventStream();
            this.datausageBouncedWebsitesEventStream();
            this.datausageTosEventStream();
            this.datausageEventStream();
            this.datausageUseragentEventStream();
        },
        load(){

            let time = new Date();
            let request = {
                'apikey': this.$root.apikey,
                current:time,
                timezone: this.timezone
            };
            axios.get(`${this.$root.serverUrl}/static/dashboard/proxy/usage`, {params:request}).then(function(resp){

                    // console.log('setDataUsageData resp data',  resp.data);
                if(resp && resp.data || resp.data.data || resp.data.data.length > 0){
                    let data = resp.data;
                    // console.log('setDataUsageData data', data);
                    this.$store.commit('setDataUsageData', data);
                    this.$eventHub.$emit('removeSpinnerUsage');

                }
            }.bind(this));

            axios.get(`${this.$root.serverUrl}/static/dashboard/proxy/usage/day`, {params:request}).then(function(resp){

                if(resp && resp.data || resp.data.data || resp.data.data.length > 0){
                    let data = resp.data;
                    // console.log('setDataUsageDataDay data', data);
                    this.$store.commit('setDataUsageDataDay', data);
                    this.$eventHub.$emit('removeSpinner');
                }
            }.bind(this));
            axios.get(`${this.$root.serverUrl}/static/dashboard/proxy/usage/impressions`, {params:request}).then(function(resp){

                    // console.log('setImpressionData resp data',  resp.data);
                if(resp && resp.data || resp.data.data || resp.data.data.length > 0){
                    let data = resp.data;
                    // console.log('setImpressionData data', data);
                    this.$store.commit('setImpressionData', data);
                    this.$eventHub.$emit('removeSpinnerImpression');

                }
            }.bind(this));
            axios.get(`${this.$root.serverUrl}/static/dashboard/proxy/usage/bounce`, {params:request}).then(function(resp){

                    // console.log('setBouncedWebsitesData resp data',  resp.data);
                if(resp && resp.data || resp.data.data || resp.data.data.length > 0){
                    let data = resp.data;
                    // console.log('setBouncedWebsitesData data', data);
                    this.$store.commit('setBouncedWebsitesData', data);
                }
            }.bind(this));
            axios.get(`${this.$root.serverUrl}/static/dashboard/proxy/usage/tos`, {params:request}).then(function(resp){

                    // console.log('setLowTosWebsitesData resp data',  resp.data);
                if(resp && resp.data || resp.data.data || resp.data.data.length > 0){
                    let data = resp.data;
                    // console.log('setLowTosWebsitesData data', data);
                    this.$store.commit('setLowTosWebsitesData', data);
                }
            }.bind(this));
            axios.get(`${this.$root.serverUrl}/static/dashboard/proxy/usage/agents`, {params:request}).then(function(resp){

                    // console.log('setUserAgentsData resp data',  resp.data);
                if(resp && resp.data || resp.data.data || resp.data.data.length > 0){
                    let data = resp.data;
                    this.$store.commit('setUserAgentsData', data);
                }
            }.bind(this));
        },
        datausageEventStream() {
            if (this.eventDataUsage) {
                this.eventDataUsage.close();
                this.eventDataUsage = null;
            }

            //Register eventstream
            if (!this.eventDataUsage) {
                let time = new Date();
                let request = new URLSearchParams({
                    apikey: this.$root.apikey,
                    current: time,
                    timezone: this.timezone,
                    //   ...this.pastDay,
                });
                this.eventDataUsage = new EventSource(
                `${this.$root.serverUrl}/static/dashboard/proxy/usage/stream?${request}`,
                {
                    withCredentials: false,
                }
                );
                this.eventDataUsage.onerror = (error) => {
                console.log(`eventDataUsage.error`, error);
                };
                this.eventDataUsage.onopen = (args) => {
                console.log(`eventDataUsage.open`, args);
                };
                this.eventDataUsage.onmessage = (event) => {
                try {
                    const parsedData = JSON.parse(event.data);

                    // console.log(`eventForecast.onmessage: `, parsedData);
                    this.$store.commit("setDataUsageData", parsedData);
                    this.$forceUpdate();
                } catch (e) {
                    console.log(`eventDataUsage.catch: ${e.message}`);
                }
                };
            }
        },
        datausageDayEventStream() {
            if (this.eventDataUsageDays) {
                this.eventDataUsageDays.close();
                this.eventDataUsageDays = null;
            }

            //Register eventstream
            if (!this.eventDataUsageDays) {
                let time = new Date();
                let request = new URLSearchParams({
                    apikey: this.$root.apikey,
                    current: time,
                    timezone: this.timezone
                    //   ...this.pastDay,
                });
                this.eventDataUsageDays = new EventSource(
                `${this.$root.serverUrl}/static/dashboard/proxy/usage/day/stream?${request}`,
                {
                    withCredentials: false,
                }
                );
                this.eventDataUsageDays.onerror = (error) => {
                console.log(`eventDataUsageDays.error`, error);
                };
                this.eventDataUsageDays.onopen = (args) => {
                console.log(`eventDataUsageDays.open`, args);
                };
                this.eventDataUsageDays.onmessage = (event) => {
                try {
                    const parsedData = JSON.parse(event.data);

                    // console.log(`eventDataUsageDays.onmessage: `, parsedData);
                    this.$store.commit("setDataUsageDataDay", parsedData);
                    this.$forceUpdate();
                } catch (e) {
                    console.log(`eventDataUsageDays.catch: ${e.message}`);
                }
                };
            }
        },
        datausageImpressionsEventStream() {
            this.$eventHub.$emit('showSpinnerUsage');

            if (this.eventDataUsageImpressions) {
                this.eventDataUsageImpressions.close();
                this.eventDataUsageImpressions = null;
            }
            //Register eventstream
            if (!this.eventDataUsageImpressions) {
                let time = this.timeX ? this.timeX :new Date();
                let request = new URLSearchParams({
                    apikey: this.$root.apikey,
                    current: time,
                    timezone: this.timezone
                    //   ...this.pastDay,
                });
                this.eventDataUsageImpressions = new EventSource(
                `${this.$root.serverUrl}/static/dashboard/proxy/usage/impressions/stream?${request}`,
                {
                    withCredentials: false,
                }
                );
                this.eventDataUsageImpressions.onerror = (error) => {
                console.log(`eventDataUsageImpressions.error`, error);
                };
                this.eventDataUsageImpressions.onopen = (args) => {
                console.log(`eventDataUsageImpressions.open`, args);
                };
                this.eventDataUsageImpressions.onmessage = (event) => {
                try {
                    const parsedData = JSON.parse(event.data);

                    // console.log(`eventDataUsageImpressions.onmessage: `, parsedData);
                    this.$store.commit("setImpressionData", parsedData);
                    this.$eventHub.$emit('removeSpinnerImpression');
                    this.$forceUpdate();
                } catch (e) {
                    console.log(`eventDataUsageImpressions.catch: ${e.message}`);
                }
                };
            }
        },
        datausageBouncedWebsitesEventStream() {
            if (this.eventBouncedWebsites) {
                this.eventBouncedWebsites.close();
                this.eventBouncedWebsites = null;
            }

            //Register eventstream
            if (!this.eventBouncedWebsites) {
                let time = new Date();
                let request = new URLSearchParams({
                    apikey: this.$root.apikey,
                    current: time,
                    timezone: this.timezone
                //   ...this.pastDay,
                });
                this.eventBouncedWebsites = new EventSource(
                `${this.$root.serverUrl}/static/dashboard/proxy/usage/bounce/stream?${request}`,
                {
                    withCredentials: false,
                }
                );
                this.eventBouncedWebsites.onerror = (error) => {
                console.log(`eventBouncedWebsites.error`, error);
                };
                this.eventBouncedWebsites.onopen = (args) => {
                console.log(`eventBouncedWebsites.open`, args);
                };
                this.eventBouncedWebsites.onmessage = (event) => {
                try {
                    const parsedData = JSON.parse(event.data);

                    // console.log(`eventBouncedWebsites.onmessage: `, parsedData);
                    this.$store.commit("setBouncedWebsitesData", parsedData);
                    this.$forceUpdate();
                } catch (e) {
                    console.log(`eventBouncedWebsites.catch: ${e.message}`);
                }
                };
            }
        },
        datausageTosEventStream() {
            if (this.eventTos) {
                this.eventTos.close();
                this.eventTos = null;
            }

            //Register eventstream
            if (!this.eventTos) {
                let time = new Date();
                let request = new URLSearchParams({
                    apikey: this.$root.apikey,
                    current: time,
                    timezone: this.timezone
                    //   ...this.pastDay,
                });
                this.eventTos = new EventSource(
                `${this.$root.serverUrl}/static/dashboard/proxy/usage/tos/stream?${request}`,
                {
                    withCredentials: false,
                }
                );
                this.eventTos.onerror = (error) => {
                console.log(`eventTos.error`, error);
                };
                this.eventTos.onopen = (args) => {
                console.log(`eventTos.open`, args);
                };
                this.eventTos.onmessage = (event) => {
                try {
                    const parsedData = JSON.parse(event.data);

                    // console.log(`eventDataUsageImpressions.onmessage: `, parsedData);
                    this.$store.commit("setLowTosWebsitesData", parsedData);
                    this.$forceUpdate();
                } catch (e) {
                    console.log(`eventTos.catch: ${e.message}`);
                }
                };
            }
        },
        datausageUseragentEventStream() {
            if (this.eventUserAgent) {
                this.eventUserAgent.close();
                this.eventUserAgent = null;
            }

            //Register eventstream
            if (!this.eventUserAgent) {
                let time = new Date();
                let request = new URLSearchParams({
                    apikey: this.$root.apikey,
                    current: time,
                    timezone: this.timezone
                //   ...this.pastDay,
                });
                this.eventUserAgent = new EventSource(
                `${this.$root.serverUrl}/static/dashboard/proxy/usage/agents/stream?${request}`,
                {
                    withCredentials: false,
                }
                );
                this.eventUserAgent.onerror = (error) => {
                console.log(`eventUserAgent.error`, error);
                };
                this.eventUserAgent.onopen = (args) => {
                console.log(`eventUserAgent.open`, args);
                };
                this.eventUserAgent.onmessage = (event) => {
                try {
                    const parsedData = JSON.parse(event.data);

                    // console.log(`eventDataUsageImpressions.onmessage: `, parsedData);
                    this.$store.commit("setUserAgentsData", parsedData);
                    this.$forceUpdate();
                } catch (e) {
                    console.log(`eventUserAgent.catch: ${e.message}`);
                }
                };
            }
        },
    }
}
</script>
