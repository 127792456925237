<template>
<div>
    <div class="card-header font-wight-bolder text-white" style="background-color:#0938b3;" >
        <div class="row">
            <div class="col-12" >
                <h5 class="pt-2">Product Data Usage</h5>
            </div>
        </div>
    </div>
        <div class="card-body">
            <div class="row">
                <div class="col-12">
                    <b-card header-tag="header">
                        <providersTable/>
                    </b-card>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import providersTable from "./providersTable.vue";
export default {
    name: "providers",
    components: {
        providersTable
    }
}
</script>

<style scoped>

</style>
