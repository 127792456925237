<template>
    <div>
        <!-- <b-form-select :options="providers" v-model="filter.provider"> </b-form-select> -->
    </div>
</template>

<script>
export default {
    name: "usageByDay",
    data() {
        return {
            filter: { provider: 'all' },
            providers: [ { value: 'all', text: 'All Providers' } ]
        }
    }
}
</script>

<style scoped>

</style>
