<template>
  <div class="">
    <div class="card">
      <div class="card-body text-center">

        <div v-if="!loadActive">

          <h4 class="text-danger" v-if="Number(getHits) >= 55">{{ Number(getHits).toLocaleString() }}</h4>
          <h4 class="text-warning" v-else-if="Number(getHits) >= 35 && Number(getHits) < 55">{{ Number(getHits).toLocaleString() }}</h4>
          <h4 class="text-success" v-else-if="Number(getHits) > 0">{{ Number(getHits).toLocaleString() }}</h4>
          <h4 v-else class="">{{ Number(getHits).toLocaleString() }}</h4>
          <h6>QSO Hits Per Sec</h6>
        </div>
        <div v-if="loadActive">
            <!-- <h4 class=""><img src="/25.gif" />  {{ Number(getHits).toLocaleString() }}</h4> -->
            <h4 class=""><img src="/25.gif" /></h4>
          <h6>QSO Hits Per Sec</h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props:['hits'],
  data: () => {
    return {
      // hits: 0,
      item: { hour: 0, minute: 0, second: 0, hits: 0, need: 0 },
      loadActive: false,
      eventQSO: null,
    };
  },
  // created() {
  //   //Register eventstream
  //   if (!this.eventQSO) {
  //     this.eventQSO = new EventSource(
  //       `${this.$root.serverUrl}/static/dashboard/hitqso`,
  //       { withCredentials: false }
  //     );
  //
  //     this.eventQSO.onerror = (error) => {
  //       console.log(`eventQSO.error`, error);
  //     };
  //     this.eventQSO.onopen = (args) => {
  //       console.log(`eventQSO.open`, args);
  //     };
  //     this.eventQSO.onmessage = (event) => {
  //       try {
  //         const parsedData = JSON.parse(event.data);
  //         this.item = Object.assign(
  //           { hour: 0, minute: 0, second: 0, hits: 0, need: 0 },
  //           parsedData[0]
  //         );
  //         this.hits = this.item.hits;
  //         this.loadActive = false;
  //         this.$forceUpdate();
  //       } catch (e) {
  //         console.log(`eventQSO.catch: ${e.message}`);
  //       }
  //     };
  //   }
  // },
  computed: {
    getHits() {
      let data = this.hits ? this.hits : 0;
      return data;
    },
    getNeeds() {
      let data = this.hits.need ? this.hits.need : 0;
      return data;
    },
  },
};
</script>

<style>
</style>
