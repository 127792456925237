<template>
    <div>
        <ejs-chart id="chartCurrentMonth"
                   :primaryXAxis="primaryXAxis"
                   :primaryYAxis="primaryYAxis"
                   :tooltip="tooltip"
                   :palettes='palettes'
                   :chartArea="chartArea">
            <e-series-collection>
                <e-series :dataSource="getToday" type="Line" xName="day" :yName="filter.view" name="Selected Month" width="2" dashArray="5,1" :marker="marker" ></e-series>
                <e-series :dataSource="getPrevious" type="Line" xName="day" :yName="filter.view" name="Past Year Month" width="2" dashArray="5,1" :marker="marker" ></e-series>
            </e-series-collection>
        </ejs-chart>
    </div>
</template>

<script>
import Vue from "vue";
import { ChartPlugin, LineSeries, Legend, Tooltip, Category } from "@syncfusion/ej2-vue-charts";

Vue.use(ChartPlugin);

export default {
    name: "currentMonth.vue",
    props:['filter'],
    data() {
        return {
            palettes: ["#E94649", "#800080"],
            primaryXAxis: {
                title: "Days",
                majorGridLines: { width: 0 },
                minorGridLines: { width: 0 },
                majorTickLines: { width: 0 },
                minorTickLines: { width: 0 },
                interval: 1,
                lineStyle: { width: 0 },
                valueType: "Category",
                labelFormat: "%{value}"
            },
            primaryYAxis: {
                title: "Shown Rate",
                lineStyle: { width: 0 },
                minimum: 0,
                // maximum: 100,
                // interval: 10,
                majorTickLines: { width: 0 },
                majorGridLines: { width: 1 },
                minorGridLines: { width: 1 },
                minorTickLines: { width: 0 },
            },
            chartArea: {
                border: {
                    width: 0
                }
            },
            marker: {
                visible: true
            },
            tooltip: {
                enable: true
            }
        };
    },
    provide: {
        chart: [LineSeries, Legend, Tooltip, Category]
    },
    computed: {
        getData() {
            var data = [];
            data = this.$store.state ? this.$store.state.seriesDataMonth : data;
            return data;
        },
        getToday() {
            var data = [];
            data = this.getData.today ? this.getData.today : data;
            return data;
        },
        getPrevious() {
            var data = [];
            data = this.getData.previous ? this.getData.previous : data;
            return data;
        },
    }
}
</script>

<style scoped>

</style>
