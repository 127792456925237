
    <template>
        <div id="app">
            <div class="container">
                <div class="row card b-card">
                    <div class="col-lg-6 col-md-6">
                        <div  class="form-group mr-1">
                            <div class="tabs-wrap">


                            </div>
                        </div>
                    </div>
                </div>
            </div>

<!--            <ejs-chart id="container" :title='title' :primaryXAxis='primaryXAxis' :tooltip='tooltip' >-->
<!--                <e-series-collection>-->
<!--                    <e-series :dataSource='seriesData' type='StackingColumn' xName='x' yName='y' name='Opened'> </e-series>-->
<!--                    <e-series :dataSource='seriesData' type='StackingColumn' xName='x' yName='y1' name='Forwarded'> </e-series>-->
<!--                    <e-series :dataSource='seriesData' type='StackingColumn' xName='x' yName='y2' name='Replied'> </e-series>-->
<!--                    <e-series :dataSource='seriesData' type='StackingColumn' xName='x' yName='y3' name='Deleted'> </e-series>-->
<!--                </e-series-collection>-->
<!--            </ejs-chart>-->


            <ejs-chart id="container" :title='title' :primaryXAxis='primaryXAxis' :tooltip='tooltip' :palettes='palettes'>
                <e-series-collection>
                    <e-series :dataSource='seriesData' type='StackingColumn' xName='x' yName='y' name='Opened'  > </e-series>
                    <e-series :dataSource='seriesData' type='StackingColumn' xName='x' yName='y1' name='Forwarded' > </e-series>
                    <e-series :dataSource='seriesData' type='StackingColumn' xName='x' yName='y3' name='Deleted'  > </e-series>
                    <e-series :dataSource='seriesData' type='StackingColumn' xName='x' yName='y2' name='Replied'  > </e-series>
                </e-series-collection>
            </ejs-chart>
        </div>

</template>
<style scoped>
.chart-content {
    height: 100%;
    width: 100%;
}
#container1, #container2, #container3 {
    height: inherit !important;
    width: inherit !important;
}
#chartcontainer {
    height: 550px;
    width: 100%;
}

@media screen and (max-width: 1024px) {
    #chartcontainer {
        height: 950px; /* Adjust the height for smaller screens */
    }
}
</style>
<script>
import Vue from "vue";
Vue.config.silent = true;
import { Browser } from '@syncfusion/ej2-base';
import { ChartPlugin, StackingColumnSeries, Legend, BarSeries, Category, Tooltip, Highlight,MultiLevelLabel, ColumnSeries, DataLabel,StackingBarSeries} from "@syncfusion/ej2-vue-charts";
import { createSpinner, showSpinner, hideSpinner } from '@syncfusion/ej2-vue-popups';
import axios from "axios";
import swal from "sweetalert";
Vue.use(ChartPlugin);

let selectedTheme = location.hash.split("/")[1];
selectedTheme = selectedTheme ? selectedTheme : "Material";
let theme = (selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)).replace(/-dark/i, "Dark").replace(/contrast/i, 'Contrast');

export default Vue.extend({
    props:['shownRate','isLoading','seriesData'],
    data: function() {
        return {
            xhrInterval: null,
            chartHeight: '100%', // Set the initial chart height
            chartWidth: '100%',
            theme: theme,
            // seriesData: [ { x: '01 June', y: 111.1, y1: 76.9, y2: 66.1, y3: 34.1 },
            //     { x: '2015', y: 127.3, y1: 99.5, y2: 79.3, y3: 38.2 },
            //     { x: '2016', y: 143.4, y1: 121.7, y2: 91.3, y3: 44.0 },
            //     { x: '2017', y: 159.9, y1: 142.5, y2: 102.4, y3: 51.6 },
            //     { x: '2018', y: 175.4, y1: 166.7, y2: 112.9, y3: 61.9 },
            //     { x: '2019', y: 189.0, y1: 182.9, y2: 122.4, y3: 71.5 },
            //     { x: '2020', y: 202.7, y1: 197.3, y2: 120.9, y3: 82.0 }],
            palettes: ["#666666","#a6761d", "#e6ab02", "#66a61e" ],
            //Initializing Primary X Axis
            primaryXAxis: {
                title: '',
                interval: 1,
                valueType: 'Category',
            },
            marker: {
                dataLabel: {
                    visible: true,
                    name: 'DataLabelMappingName',
                    position: Browser.isDevice ? 'Middle' : 'Middle',
                    font: { fontWeight: '600', color: Browser.isDevice ? '#404041' : '#0c0c0c' }
                }
            },
            marker2: {
                dataLabel: {
                    visible: true,
                    name: 'DataLabel',
                    position: Browser.isDevice ? 'Top' : 'Top',
                    font: { fontWeight: '600', color: Browser.isDevice ? '#404041' : '#0c0c0c' }
                }
            },
            //Initializing Primary Y Axis
            primaryYAxis:
                {
                    rangePadding: 'None',
                    interval: 2000,
                    title: 'Number Of Clicks Per Hour',
                    lineStyle: { width: 0 },
                    majorTickLines: { width: 0 },
                    majorGridLines: { width: 1 },
                    minorTickLines: { width: 0 },
                },
            chartArea: {
                border: {
                    width: 0
                }
            },
            tooltip: {
                enable: true,
                format: '${point.x} : <b >${point.y}</b>'
            },
            border: {color: '#ffffff', width:1},
            legend: {enableHighlight : true, },
            width : Browser.isDevice ? '100%' : '100%',
            type: Browser.isDevice ? 'StackingBar' : 'StackingColumn',
            type2: Browser.isDevice ? 'Bar' : 'Column',
            title: "Inbox Enhanced"
        };
    },
    mounted() {
        createSpinner({
            // Specify the target for the spinner to show
            target: document.getElementById('container')
        });

    },
    // created:{
    //
    // },
    provide: {
        chart: [StackingColumnSeries, Category, Tooltip, Legend, Highlight,MultiLevelLabel,ColumnSeries, DataLabel,StackingBarSeries,BarSeries]
    },
    beforeDestroy() {

    },
    methods: {
        parseDateString(dateStr) {
            const monthMap = {
                Jan: 0, Feb: 1, Mar: 2, Apr: 3, May: 4, Jun: 5,
                Jul: 6, Aug: 7, Sep: 8, Oct: 9, Nov: 10, Dec: 11
            };

            const [day, month] = dateStr.split(' ');
            const dateObj = new Date(2024, monthMap[month], parseInt(day));

            // Return timestamp for direct comparison
            return dateObj.getTime();
        },
        async search(){
            showSpinner(document.getElementById('container'));
            try
            {
                this.searching = true;
                this.showExportButton = false;
                this.reportLoading = true;
                this.reports = [];


                const request = {'apikey': this.$root.apikey, sSearch:null, filters:{}};
                // if(this.filters.client) request.filters.client = this.filters.client;
                // if(this.filters.campaign) request.filters.campaign = this.filters.campaign;
                // if(this.filters.end) request.filters.end = this.filters.end;
                // if(this.filters.start) request.filters.start = this.filters.start;
                // if(this.filters.timePeriod) request.filters.timePeriod = this.filters.timePeriod;
                request.user = this.$store.state.user

                const response = await axios.get(`${this.$root.serverUrl}/static/dashboard/overviewChart`,{params:request});
                if(response.data.error){
                    this.reportLoading = false;
                    swal({title:'Oops', text: `${response.data.error.message}`, icon: 'error'})}
                else {
                   console.log(response)
                    hideSpinner(document.getElementById('chartcontainer'));
                }

            }catch (e) {
                console.log(e);
                this.reportLoading = false;
                this.searching = false;
                swal({title:'Oops', text: `Sorry, I am unable to complete your request. ${e.message}`, icon: 'error'})
            }
        },

    },

});
</script>

