<template>
    <div id="app">
        <ejs-grid :dataSource='filter !== "past" ? getToday: getPrevious' height='700px' :allowGrouping="true" :groupSettings="groupOptions">
            <e-columns>
                <e-column field='dayOfWeek' headerText='Days of the week' textAlign='left' width=120></e-column>
                <e-column field='clicks' headerText='% of Clicks that Occur on this day' textAlign='right' width=150 :template="format"></e-column>
                <e-column field='shown_rate' headerText='Shown Rate Percentage'  textAlign='right' width=120 :template="shownFormat" ></e-column>
                <e-column field='monthName' headerText='month' format='C2' width=120 ></e-column>
            </e-columns>

            <e-aggregates>
                <e-aggregate>
                    <e-columns>
                        <e-column type="Sum" field="clicks" format="N2"  textAlign='right' :groupFooterTemplate ='footerSum'></e-column>
                    </e-columns>
                </e-aggregate>
              <!--  <e-aggregate>
                   <e-columns>
                       <e-column type="Average" field="ofClicks" format="C2" :groupCaptionTemplate ='footerAvg'></e-column>
                   </e-columns>
               </e-aggregate>-->
            </e-aggregates>
        </ejs-grid>
    </div>
</template>

<script>
import Vue from "vue";
import { GridPlugin, Group, Aggregate } from "@syncfusion/ej2-vue-grids";

Vue.use(GridPlugin);

export default {
    name: "currentDay.vue",
    props:['filter'],
    data() {
        return {

            groupOptions: {showDropArea: false, columns: ['monthName'] },
            format: "<div>${ parseFloat(clicks).toFixed(2) }%</div>",
            shownFormat: "<div>${ parseFloat(shown_rate).toFixed(2) }%</div>",
            footerSum: function () {
                return  { template : Vue.component('sumTemplate', {
                        template: `<span>{{Math.round(data.Sum)}}%</span>`,
                        data () {return { data: {}};}
                    })
                }
            },
            footerAvg: function () {
                return  { template : Vue.component('maxTemplate', {
                        template: `<span>Average: {{data.Average}}</span>`,
                        data () {return { data: {}};}
                    })
                }
            }
        };
    },
    provide : {
        grid: [Group, Aggregate]
    },
    computed: {
        getData() {
            var data = [];
            data = this.$store.state ? this.$store.state.currentDay : data;
            return data;
        },
        getToday() {
            var data = [];
            data = this.getData.today ? this.getData.today : data;
            return data;
        },
        getPrevious() {
            var data = [];
            data = this.getData.previous ? this.getData.previous : data;
            return data;
        },
    },
}
</script>
<style>
@import "../../../../../node_modules/@syncfusion/ej2-base/styles/material.css";
</style>

