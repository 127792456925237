<template>
    <div class="container mt-5">
        <h1 class="text-center">Compose Email</h1>
        <div class="row">
            <div class="col-md-12 3">
                <form @submit.prevent="sendEmail" @keydown.enter.prevent>
                    <div class="form-group">
                        <label for="recipient">Recipient:</label>
                        <input-tag v-model="recipient"></input-tag>

                    </div>
                    <div class="form-group">
                        <label for="subject">Subject:</label>
                        <input v-model="subject" type="text" class="form-control" id="subject" required>
                    </div>
                    <div class="form-group">
                        <label for="message">Message:</label>
                        <textarea v-model="message" class="form-control" id="message" rows="6" placeholder="Your message" required ></textarea>
                    </div>
                    <div class="form-group mt-2">
                        <label for="pdfAttachment">PDF Attachment:</label>
                        <input type="file" id="pdfAttachment" ref="pdfInput"  @change="handleFileChange" accept=".pdf"  required>
                    </div>
                    <button type="submit" class="btn btn-success btn-sm d-flex justify-content-center align-items-center mx-auto">Send Email</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import swal from "sweetalert2";
import InputTag from 'vue-input-tag';
Vue.component('input-tag', InputTag)
import Vue from "vue";

export default {
    props:['pdfInputField','recipient'],
    data() {
        return {

            subject: 'Weekly Clicks Review',
            pdfAttachment: null,
            message: `Good day,
            We hope this message finds you well. Included in this email is information about the clicks delivered for the past week (Monday - Sunday).
            Feel free to reach out if you have any questions or need further information.

            Best regards,
            Scale Agile Solution`,
        };
    },
    mounted() {
        //this.handleFileChange()
    },
    methods: {

        handleFileChange(event) {
            this.pdfAttachment = event.target.files[0];
            this.$forceUpdate()// Get the selected file from the input
        },
        async sendEmail() {
            if (!this.recipient || !this.subject || !this.message || !this.pdfAttachment) {
                swal.fire({title: 'Error', text: 'Please fill in all required fields and select a PDF file.', icon: 'Warning'});
                return;
            }

            const formData = new FormData();
            formData.append('recipient', this.recipient);
            formData.append('subject', this.subject);
            formData.append('message', this.message);
            formData.append('pdfAttachment', this.pdfAttachment);

            try {
                swal.fire({title: 'Processing', text: 'Sending Emails Please Wait.', icon: 'warning'});
                console.log(formData);
                axios.post(`${this.$root.serverUrl}/static/send-email`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }).then((resp)=>{
                    console.log('Email sent successfully', resp);
                    swal.fire({title: 'Processed', text: 'Email Sent.', icon: 'success'});
                    this.scaling = false;
                }).catch((err)=>{
                    console.log(`Email sending failed`,err);
                    swal.fire({title: 'Error', text: 'Emails not sent please try again', icon: 'error'});
                }).finally(()=>{
                    this.processing = false;
                })
            } catch (error) {
                console.error('Error sending email:', error);
            }
        },
    },
};
</script>

<style scoped>
@import "../../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-vue-navigations/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-vue-calendars/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-vue-popups/styles/material.css";
</style>
